import { useEffect, useRef, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

import EventService from '../../services/EventService';

import './JudgeModal.scss';

function JudgeScoringModal(props: any) {
  const { onHide, name } = props;
  const [criterialist, setCriterialist] = useState<any>([]);
  const [getJudgedScore, setGetJudgedScore] = useState<any>([]);
  const [subId, setSubId] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const list = useRef([]);
  const submissionId = useRef([]);
  const Scorelist = useRef([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [inputValidate, setInputValidate] = useState('score-points form-control');

  const [getJudgeId, setGetJudgeId] = useState(null);

  const [getIndividualJudgedScore, setGetIndividualJudgedScore] = useState<any>([]);
  const update = useRef(false);
  const inputScore = useRef<any[]>([]);
  submissionId.current = props.id;
  const { eventId } = useParams();

  const getPlaceholder = (numberOfElements: number) => {
    const content: any = [];
    for (let i = 0; i < numberOfElements; i += 1) {
      content.push(
        <div className="d-flex justify-content-between px-3" key={i}>
          <Placeholder className="d-block my-3 mx-3" xs={4} bg="secondary" key={v4()} />
          <Placeholder className="d-block my-3 mx-3" xs={4} bg="secondary" key={v4()} />
        </div>,
      );
    }
    return content;
  };

  useEffect(() => {
    const getJudge = async () => {
      await EventService.getJudgementDataById(eventId).then(async (response): Promise<any> => {
        setCriterialist(response.data.message.criteriaData);
        list.current = response.data.message.criteriaData;
        setDataLoading(false);

        await EventService.getJudgementBySubmissionIdJudgeId(props.id)
          .then(async (res: any) => {
            update.current = true;
            setSubId(true);
            setGetJudgeId(res.data.message.judgementData);
            setGetIndividualJudgedScore(res.data.message.criteria);
            setGetJudgedScore(res.data.message.judgeCriteriaScoreData);
            Scorelist.current = res.data.message.judgeCriteriaScoreData;
          })
          .catch((err: any) => {
            console.error(err);
          });
      });
    };
    getJudge();
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const result: { criteriaName: any; score: any }[] = [];
    inputScore.current.map((data: any, index: any) => {
      const scoreData = {
        criteriaName: data.id,
        score: Number(data.value),
      };
      result.push(scoreData);
    });
    const ScoreData = {
      data: result,
      eventId,
      submissionId: props.id,
    };
    toast.success('Saved Judgement Score');
    EventService.postJudgementScoreData(ScoreData).then((data: any) => {
      onHide();
      window.location.reload();
    });
  };

  const chooseValue = (name: any): number => {
    const filteredData = getJudgedScore.filter((data: any) => {
      if (data.criteriaName === name) {
        return data;
      }
    });
    return filteredData[0]?.score;
  };

  const validate = (event: any, maxScore: number) => {
    if (event.target.value > maxScore) {
      setDisable(true);
      setInputValidate('score-points form-control error');
    } else {
      setDisable(false);
      setInputValidate('score-points form-control');
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="judgeModal"
      centered
    >
      <Modal.Body className="score-card">
        <div className="row pt-1">
          <p className="card-heading">Add Score</p>
          <CloseButton onClick={onHide} className="text-muted close-btn" />
        </div>
        <p className="text-center title">{name}'s Entry</p>
        <form className="justify-content-around">
          <div className="py-3">
            {!dataLoading ? (
              criterialist.map((criteria: any, index: any) => (
                <div
                  className="d-flex align-items-center justify-content-between criteria_box my-2"
                  key={criteria.id}
                >
                  <label className="h5 text-capitalize criteria">{criteria.criteriaName}</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="number"
                      id={criteria.criteriaName}
                      className={inputValidate}
                      max={criteria.max_score}
                      onChange={(e) => {
                        validate(e, criteria.max_score);
                      }}
                      placeholder="Score"
                      ref={(ref) => {
                        inputScore.current[index] = ref;
                      }}
                      defaultValue={chooseValue(criteria.criteriaName)}
                    />

                    <p className="h5 m-0 ms-1 ps-1 score">
                      out of
                      <span className="ps-2 max-score text-muted">{criteria.max_score}</span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <Placeholder animation="glow">{getPlaceholder(4)}</Placeholder>
            )}
            {/* loader */}
          </div>
          <p className="name text-muted">*Please add your scores for each criteria.</p>

          <div className="d-flex justify-content-center p-3">
            <button
              type="button"
              className="btn btn-secondary button-type button me-3"
              onClick={props.onHide}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-primary button-type button text-white ms-3"
              onClick={onSubmit}
              disabled={disable || loading}
            >
              Save
              {loading ? (
                <span className="ms-2 spinner-border spinner-border-sm text-white" role="status" />
              ) : null}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default JudgeScoringModal;
