import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import EventService from '../../services/EventService';

function LeaderBoardConfirmationModal({ show, onHide, eventId }: any) {
  const publish = () => {
    EventService.enableLeaderboardForEvent(eventId).then(() => {
      toast.success('LeaderBoard is sucessfully Published');
      onHide();
    });
  };
  return (
    <Modal show={show} onHide={onHide} className="text-center">
      <Modal.Header closeButton>
        <Modal.Title className="h5">Publish Leader Board</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-start">
        <p>
          A Preview can be seen at :{' '}
          <Link to={`/event/${eventId}/entries/leaderboard`} style={{ color: '#0000EE' }}>
            Leader Board
          </Link>
        </p>
        <p> Are you sure you want to publish the Leader Board?</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end pt-1">
        <Button variant="primary" className="text-white" onClick={publish}>
          Yes
        </Button>
        <Button variant="secondary" onClick={onHide} className="text-white">
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LeaderBoardConfirmationModal;
