import { Auth } from 'aws-amplify';

import http from '../utils/HttpUtils';

const isAdmin = () => {
  return http.get('me/role');
};

const fedratedUser = () => {
  return Auth.federatedSignIn({ customProvider: 'presidioAD' })
    .then(() => {
      return Auth.currentSession().then((data) => {
        const obj = JSON.parse(JSON.stringify(data));
        localStorage.setItem('login', JSON.stringify(obj.idToken));
        localStorage.setItem('email', JSON.stringify(obj.idToken.payload.email));
        localStorage.setItem('Jwt', JSON.stringify(obj.idToken.jwtToken));
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

const AuthService = {
  isAdmin,
  fedratedUser,
};

export default AuthService;
