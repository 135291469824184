import { Link } from 'react-router-dom';

function SubmitButton(props: any) {
  const { eventId, isEnable } = props;

  const style = {};

  // if (!isEnable) {
  //   style = {
  //     background: "#FFEEAF",
  //   };
  // }

  return (
    <Link
      to={`/submission/${eventId}`}
      className="btn btn-sm outline btn-outline-primary text-uppercase text-primary ms-1"
      style={style}
    >
      <i className="bi bi-pencil-square" />
      <span>&nbsp; SUBMIT</span>
    </Link>
  );
}

export default SubmitButton;
