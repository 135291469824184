import { useEffect, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import SpinnerReact from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sanitize } from 'dompurify';

import metroimg from '../../assets/metroimage.png';
import { DISPLAY_EVENT_STATUS } from '../../common/constants/displayEventStatus';
import { EVENT_STATUS } from '../../common/enums/eventStatus.enum';
import ApprovalActionModal from '../../components/ApprovalModal/ApprovalActionModal';
import LeaderBoardConfirmationModal from '../../components/LeaderBoardConfirmationModal/LeaderBoardConfirmationModal';
import { deleteEvent, getEventById } from '../../redux/actions/EventAction/eventActions';
import { reset } from '../../redux/actions/FormAction/FormAction';
import approvalService from '../../services/ApprovalService';
import EventService from '../../services/EventService';
import RegisterButton from '../../shared/Buttons/RegisterButton';
import SubmitButton from '../../shared/Buttons/SubmitButton';
import ViewEntries from '../../shared/Buttons/ViewEntries';
import ViewForm from '../../shared/Buttons/ViewForm';
import ViewLeaderBoard from '../../shared/Buttons/ViewLeaderBoard';

import './EventDetails.scss';

function EventDetails() {
  const { eventId } = useParams();
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<{ [key: string]: any }>({});
  const [coverPic, setcoverPic] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [regId, setRegId] = useState('');
  const [registrationStartDate, setRegistrationStartDate] = useState('');
  const [registrationEndDate, setRegistrationEndDate] = useState('');
  const [submissionStartDate, setSubmissionStartDate] = useState('');
  const [submissionEndDate, setSubmissionEndDate] = useState('');
  const [judgementStartDate, setJudgementStartDate] = useState('');
  const [judgementEndDate, setJudgementEndDate] = useState('');

  const [profilePic, setProfilePic] = useState(null);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [eventLog, setEventLog] = useState<any>({});
  const [isJudge, setIsJudge] = useState(false);
  const [isParticipant, setIsParticipant] = useState(false);
  const [isMultipleSubmissionEnabled, setIsMultipleSubmissionEnabled] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRegistrationEnabled, setIsRegistrationEnabled] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [viewButtonByRole, setViewButtonByRole] = useState('VIEW LEADERBOARD');
  const [isLeaderBoardPublished, setIsLeaderBoardPublished] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showHide, setShowHide] = useState(true);
  const [modalShowApprove, setModalShowApprove] = useState(false);
  const [status, setStatus] = useState('');
  const navigate = useNavigate();
  const [subId, setSubId] = useState('');
  const { id } = event;

  const handleAction = (status: string) => {
    setModalShowApprove(true);
    setStatus(status);
  };
  const { isAdmin } = useSelector((state: any) => state.rootReducer.AuthReducer);

  const submitApproval = (approvalStatus: any, comments: any) => {
    const req = {
      eventId: id,
      status: approvalStatus.toUpperCase(),
      comments,
    };
    const result = EventService.approveEvent(req);
    result.then(() => {
      if (req.status === 'APPROVE') {
        toast.success('Approved Event');
      } else if (req.status === 'DISCARD') {
        toast.error('Discarded the event');
      } else if (req.status === 'ACTION REQUIRED') {
        toast.warn('Event sent for Action required');
      }
      setModalShowApprove(false);
      navigate(-1);
    });
    result.catch(() => {
      toast.error('An Error occured while saving the changes');
    });
  };

  const dispatch = useDispatch();

  const { pathname: path } = useLocation();
  const state = !path.includes('approver');
  const organizerView = !path.includes('event');

  const onEdit = (eventId: any) => {
    dispatch(reset());
    navigate(`/edit/${eventId}`);
  };

  const onDelete = (id: any) => {
    dispatch(deleteEvent(id));
    navigate(`/`);
  };

  const displayEditiMenu = () => {
    setShowMenu((previousValue) => !previousValue);
  };

  useEffect(() => {
    dispatch(getEventById(eventId));
    approvalService.getEventUserRole(eventId).then((response) => {
      const { roles } = response.data.message;
      setIsOrganizer(roles.isOrganizer);
      setIsJudge(roles.isJudge);
      setIsParticipant(roles.isParticipant);
      setIsSubmitted(roles.isSubmitted);
      if (roles.isOrganizer || roles.isJudge) {
        setViewButtonByRole('VIEW ENTIRES');
      }
    });

    if (state) {
      approvalService.getEventLog(eventId).then((response) => {
        const { eventLog } = response.data.message;

        eventLog.approvedDate = new Date(eventLog.approvedDate).toLocaleDateString();

        setEventLog(eventLog);
      });
    }

    EventService.getEvent(eventId)
      .then(async (response: any) => {
        const event = response.data.message;
        setEvent(event);
        setLoading(false);

        Promise.all([
          EventService.downloadImage(response.data.message.coverPic),
          EventService.downloadImage(response.data.message.profilePic),
        ]).then((response) => {
          setcoverPic(response[0].data.img);
          setProfilePic(response[1].data.img);
          setDataLoading(false);
        });
        setStartDate(new Date(response.data.message.startDate).toUTCString().slice(5, 16));
        setEndDate(new Date(response.data.message.endDate).toUTCString().slice(5, 16));
        setRegistrationStartDate(
          new Date(response.data.message.registrationStartDate).toUTCString().slice(5, 16),
        );
        setRegistrationEndDate(
          new Date(response.data.message.registrationEndDate).toUTCString().slice(5, 16),
        );
        setSubmissionStartDate(
          new Date(response.data.message.submissionStartDate).toUTCString().slice(5, 16),
        );
        setSubmissionEndDate(
          new Date(response.data.message.submissionEndDate).toUTCString().slice(5, 16),
        );
        setJudgementStartDate(
          new Date(response.data.message.judgementStartDate).toUTCString().slice(5, 16),
        );
        setJudgementEndDate(
          new Date(response.data.message.judgementEndDate).toUTCString().slice(5, 16),
        );
        setIsRegistrationEnabled(response.data.message.RegistrationForm.isEnabled);
        setIsSubmitEnabled(response.data.message.SubmissionForm.isEnabled);
        setIsLeaderBoardPublished(response.data.message.isLeaderboardEnabled);
        setRegId(response.data.message.Registration[0].id);
        setSubId(response.data.message.Submission[0].id);
        setIsMultipleSubmissionEnabled(
          response.data.message.SubmissionForm.isMultipleSubmissionEnabled,
        );
        const responseJudge = await EventService.getJudgementDataById(eventId);

        const initialDate = new Date(responseJudge.data.message.judgementFormData[0].startDate);
        const finalDate = new Date(responseJudge.data.message.judgementFormData[0].endDate);
        const presentDate = new Date();

        if (presentDate <= finalDate && presentDate >= initialDate) {
          setIsPublished(true);
        }
      })
      .catch(() => {});
  }, []);
  const getPlaceholder = (numberOfElements: number) => {
    const content: any = [];
    for (let i = 0; i < numberOfElements; i += 1) {
      content.push(<Placeholder lg={12} bg="secondary" key={i} />);
    }
    return content;
  };
  const enableLeaderboardForEvent = () => {
    setModalShow(true);
  };
  const back = () => {
    if (state) {
      navigate('/');
    } else {
      navigate('/requests');
    }
  };

  const eventStatus = (eventStatus: string) => {
    if (eventStatus === EVENT_STATUS.APPROVED) {
      return DISPLAY_EVENT_STATUS.APPROVED;
    }

    if (eventStatus === EVENT_STATUS.REQUESTED) {
      return DISPLAY_EVENT_STATUS.REQUESTED;
    }

    if (eventStatus === EVENT_STATUS.ACTION_REQUIRED) {
      return DISPLAY_EVENT_STATUS.ACTION_REQUIRED;
    }

    if (eventStatus === EVENT_STATUS.REJECTED) {
      return DISPLAY_EVENT_STATUS.DISCARD;
    }

    return DISPLAY_EVENT_STATUS.DRAFT;
  };

  let convertedStatus = event.commonStatus;

  if (isOrganizer) {
    convertedStatus = eventStatus(event.status);
  }

  const setColor = {
    backgroundColor:
      convertedStatus === DISPLAY_EVENT_STATUS.ACTION_REQUIRED
        ? '#FF9E16'
        : convertedStatus === DISPLAY_EVENT_STATUS.DISCARD
          ? '#E64848'
          : convertedStatus === DISPLAY_EVENT_STATUS.APPROVED ||
              convertedStatus === DISPLAY_EVENT_STATUS.ONGOING ||
              convertedStatus === DISPLAY_EVENT_STATUS.REGISTRATION_STARTED ||
              convertedStatus === DISPLAY_EVENT_STATUS.SUBMISSION_STARTED ||
              convertedStatus === DISPLAY_EVENT_STATUS.REGISTERED ||
              convertedStatus === DISPLAY_EVENT_STATUS.SUBMITTED
            ? '#24C142'
            : convertedStatus === DISPLAY_EVENT_STATUS.REQUESTED
              ? '#FF9E16'
              : convertedStatus === DISPLAY_EVENT_STATUS.DRAFT ||
                  convertedStatus === DISPLAY_EVENT_STATUS.REGISTRATION_CLOSED ||
                  convertedStatus === DISPLAY_EVENT_STATUS.SUBMISSION_CLOSED
                ? '#7e7e7e'
                : convertedStatus === DISPLAY_EVENT_STATUS.UPCOMING
                  ? '#00ADF0'
                  : '#7e7e7e',
    top: '16px',
    left: '24px',
  };

  return (
    <>
      {modalShowApprove && (
        <ApprovalActionModal
          show={modalShowApprove}
          onHide={() => setModalShowApprove(false)}
          status={status}
          event={event}
          submitapproval={submitApproval}
        />
      )}
      {modalShow && (
        <LeaderBoardConfirmationModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          eventId={eventId}
        />
      )}
      <div className="p-3 event-detail">
        <div className="bg-white py-3 wrapper__box">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <span onClick={back} role="button" tabIndex={0} className="fw-bold fs-6">
                <i className="bi bi-chevron-left me-2" />
                {state ? 'Back To Dashboard' : 'Back To Requests'}
              </span>
            </div>
            <div className="mt-4 px-md-5 image_div">
              <div className="banner rounded">
                {state && (
                  <div
                    className="position-absolute px-3 text-light rounded-pill event-status-title"
                    style={setColor}
                  >
                    {convertedStatus}
                  </div>
                )}
                <div>
                  {showHide && state && isOrganizer ? (
                    <div
                      className="position-absolute edit-events text-muted dropdown"
                      onClick={displayEditiMenu}
                      role="button"
                      tabIndex={0}
                    >
                      <i className="bi bi-three-dots-vertical" />
                    </div>
                  ) : null}
                  {showMenu && (
                    <div className="position-absolute menu-box">
                      {event.status !== EVENT_STATUS.APPROVED &&
                      event.status !== EVENT_STATUS.REQUESTED ? (
                        <>
                          {event.status !== EVENT_STATUS.REJECTED && (
                            <button
                              type="button"
                              className="btn text-primary menu-box_button text-start"
                              onClick={() => {
                                onEdit(event.id);
                                displayEditiMenu();
                              }}
                              disabled={disableButton}
                            >
                              <i className="bi bi-pencil-square pe-2" />
                              Edit Event
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn text-danger menu-box_button text-start"
                            onClick={() => {
                              onDelete(event.id);
                              displayEditiMenu();
                            }}
                            disabled={disableButton}
                          >
                            <i className="bi bi-trash-fill pe-2" />
                            Delete Event
                          </button>
                        </>
                      ) : null}

                      {state &&
                        event.status !== 'DRAFT' &&
                        event.status !== 'REJECTED' &&
                        event.status !== 'ARCHIVE' && (
                          <>
                            {event.status === DISPLAY_EVENT_STATUS.APPROVED &&
                              isOrganizer &&
                              isPublished &&
                              !isLeaderBoardPublished && (
                                <button
                                  type="button"
                                  className="btn btn-outline-primary text-primary menu-box_button text-start bg-white border-0"
                                  onClick={() => {
                                    enableLeaderboardForEvent();
                                    displayEditiMenu();
                                  }}
                                >
                                  <i className="bi bi-clipboard2-check pe-2" />
                                  Publish Leaderboard
                                </button>
                              )}
                            {isOrganizer && event.status === 'APPROVED' && (
                              <span className="view-form">
                                <ViewForm id={event.id} state={state} />
                              </span>
                            )}
                          </>
                        )}

                      {isOrganizer && event.status === 'APPROVED' && (
                        <ViewEntries id={event.id} state={state} />
                      )}
                    </div>
                  )}
                  {isAdmin && organizerView && (
                    <div className="d-flex flex-column ">
                      <div className="dropdown text-center dropdownbox text-white">
                        <button
                          className="btn border border-1 btn-sm dropdown-toggle p-2 text-dark"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Choose an action
                        </button>
                        <div>
                          <div
                            className="font-size border border-1 dropdown-menu "
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <button
                              className="dropdown-item text-success"
                              type="button"
                              onClick={() => handleAction(DISPLAY_EVENT_STATUS.APPROVE)}
                            >
                              Approve
                            </button>
                            <button
                              className="dropdown-item text-danger"
                              type="button"
                              onClick={() => handleAction(EVENT_STATUS.DISCARD)}
                            >
                              Discard
                            </button>
                            <button
                              className="dropdown-item text-warning"
                              type="button"
                              onClick={() => handleAction(DISPLAY_EVENT_STATUS.ACTION_REQUIRED)}
                            >
                              Action required
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!dataLoading && (
                    <img
                      src={coverPic || metroimg}
                      alt=""
                      className="w-100 cover-image rounded"
                      height={300}
                    />
                  )}
                  {/* START: cover Image loader */}
                  {dataLoading && (
                    <div className="spinner-banner">
                      <SpinnerReact
                        className="spin"
                        animation="border"
                        role="status"
                        variant="secondary"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </SpinnerReact>
                    </div>
                  )}
                  {/* END: Cover Image loader */}
                  {state && event.status !== 'REJECTED' ? (
                    <div className="banner_event d-md-flex">
                      {!isOrganizer && !isJudge && !isParticipant && isRegistrationEnabled && (
                        <RegisterButton eventId={event.id} />
                      )}
                      {!isOrganizer &&
                        !isJudge &&
                        (isMultipleSubmissionEnabled || !isSubmitted) &&
                        isSubmitEnabled &&
                        (isParticipant || !isRegistrationEnabled) && (
                          <SubmitButton eventId={event.id} />
                        )}

                      {isLeaderBoardPublished &&
                        isParticipant &&
                        !isOrganizer &&
                        !isJudge &&
                        isSubmitted && <ViewLeaderBoard eventId={event.id} />}
                    </div>
                  ) : (
                    !state && (
                      <div className="event_form">
                        <ViewForm id={event.id} state={state} />
                      </div>
                    )
                  )}
                  {isJudge && (
                    <div className="event_form">
                      <ViewEntries id={event.id} state={state} view="judge" />
                    </div>
                  )}
                </div>
                <div>
                  {(showHide && !isOrganizer && !isJudge && !isAdmin && isSubmitted) ||
                  isParticipant ? (
                    <div
                      className="position-absolute edit-events text-muted dropdown"
                      onClick={displayEditiMenu}
                      role="button"
                      tabIndex={0}
                    >
                      <i className="bi bi-three-dots-vertical" />
                    </div>
                  ) : null}
                  {showMenu && (
                    <div className="position-absolute menu-box" style={{ width: '16%' }}>
                      {isParticipant && (
                        <button type="button" className="btn btn-sm text-start button-section">
                          <Link to={`/registration/${eventId}/${regId}`} className="button-section">
                            <i className="bi bi-list-ul pe-2" />
                            View Your Registeration
                          </Link>
                        </button>
                      )}

                      {isSubmitted && (
                        <button type="button" className="btn btn-sm text-start button-section">
                          <Link to={`/submission/${eventId}/${subId}`} className="button-section">
                            <i className="bi bi-list-ul pe-2" />
                            View Your Submission
                          </Link>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="banner-body d-md-flex">
                <div>
                  {!dataLoading && (
                    <img className="banner-profile" src={profilePic || metroimg} alt="img" />
                  )}

                  {/* START: Profile Image loader */}
                  {dataLoading && (
                    <div className="spiner-design">
                      <SpinnerReact
                        className="spin-card"
                        animation="border"
                        role="status"
                        variant="secondary"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </SpinnerReact>
                    </div>
                  )}
                  {/* END: Profile Image loader */}
                </div>
                <div className="mt-2 mt-md-4 flex-grow-1">
                  <Placeholder animation="glow">
                    <h5 className="d-inline fw-bold me-md-5 text-capitalize event-name ms-md-4 ">
                      {event.name || <Placeholder xs={2} bg="secondary" />}
                    </h5>
                    <div className="ms-4 mt-2">
                      <p className="d-md-inline me-3 me-md-5 created-by">
                        {event.createdBy
                          ? 'Created by '
                          : null || <Placeholder xs={1} bg="secondary" />}
                        <span>{event.createdBy || <Placeholder xs={1} bg="secondary" />}</span>
                      </p>
                      <p className="d-md-inline me-md-5 fw-bold date">
                        {startDate || <Placeholder xs={2} bg="secondary" />} -{' '}
                        {endDate || <Placeholder xs={2} bg="secondary" />}
                      </p>
                    </div>
                  </Placeholder>
                </div>
              </div>
              <div className="mt-4 mt-md-5 ms-1 ms-md-5">
                {!loading && (
                  <p
                    className="banner-description"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(event.description),
                    }}
                  />
                )}

                {/* START : Description loader */}

                {loading && (
                  <p className="me-5">
                    <Placeholder animation="wave">{getPlaceholder(12)}</Placeholder>
                  </p>
                )}

                {/* END : Description loader */}

                {state && isOrganizer && !loading && (
                  <div className="pt-3">
                    {eventLog.message && (
                      <>
                        <p className="text-dark fw-bold fs-5">Approval Status</p>
                        <div className="d-flex">
                          <p className="text-secondary text-capitalize fw-bold">
                            {`${eventLog.message
                              .substring(0, eventLog.message.indexOf(' '))
                              .toLowerCase()} By:`}
                          </p>
                          <p className="text-dark text-capitalize ms-2">
                            {eventLog.message.substring(eventLog.message.indexOf(' ') + 3)}
                          </p>
                        </div>
                      </>
                    )}
                    {eventLog.comments && (
                      <div className="d-flex">
                        <p className="text-secondary fw-bold">Comments:</p>
                        <p className="text-dark pl-3 ms-2 text-capitalize event_comments">
                          {eventLog.comments}
                        </p>
                      </div>
                    )}
                    {eventLog.approvedDate && (
                      <div className="d-flex">
                        <p className="text-secondary fw-bold">Date:</p>
                        <p className="text-dark pl-3 ms-2 text-capitalize">
                          {eventLog.approvedDate}
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {/* START: Approver Comments loader */}
                {state && isOrganizer && loading && (
                  <p className="pt-3">
                    <Placeholder animation="wave">
                      <Placeholder className="d-block my-2" xs={3} bg="secondary" />
                      <Placeholder className="d-block my-2" xs={3} bg="secondary" />
                      <Placeholder className="d-block my-2" xs={3} bg="secondary" />
                      <Placeholder className="d-block my-2" xs={3} bg="secondary" />
                      <Placeholder className="d-block my-2" xs={3} bg="secondary" />
                    </Placeholder>
                  </p>
                )}
                {/* END: Approver Comments loader */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventDetails;
