import http from '../utils/HttpUtils';

const getEventLog = (id: any) => {
  return http.get(`approval-comments/${id}`);
};

const getEventUserRole = (id: any) => {
  return http.get(`event-role/${id}`);
};

export default {
  getEventLog,
  getEventUserRole,
};
