import React from 'react';
import { ReactFormBuilder } from 'react-form-builder2';
import { connect } from 'react-redux';

import {
  setRegistraionDetail,
  setSubmissionDetail,
} from '../../redux/actions/FormAction/FormAction';
import EventService from '../../services/EventService';
import { DndWrapper } from '../../shared/Dnd/DndWrapper';

const items = [
  {
    key: 'TextInput',
  },
  {
    key: 'NumberInput',
  },
  {
    key: 'TextArea',
  },
  {
    key: 'RadioButtons',
  },
  {
    key: 'Checkboxes',
  },
  {
    key: 'Dropdown',
  },
  {
    key: 'DatePicker',
  },
  {
    key: 'Image',
  },

  {
    key: 'FileUpload',
  },
  {
    key: 'Camera',
  },
];

class FormBuilder extends React.Component<any, any> {
  onPost = (data: any) => {
    const formValue = {
      eventId: this.props.eventId,
      template: data,
      isEnabled: true,
    };
    if (this.props.currentStep === 2) {
      this.props.setRegistraionDetail(formValue);
    }
    if (this.props.currentStep === 3) {
      this.props.setSubmissionDetail(formValue);
    }
  };

  onLoad = () => {
    let task_data: any[] = [];
    if (this.props.currentStep === 2) {
      return EventService.getRegistrationFormById(this.props.eventId)
        .then((res: any) => {
          if (Object.keys(res.data.message.template).length === 0) {
            task_data = [];
          } else {
            task_data = res.data.message.template.task_data;
          }
          return Promise.resolve(task_data);
        })
        .catch((error: any) => {
          console.error('error', error);
          return Promise.resolve([]);
        });
    }
    if (this.props.currentStep === 3) {
      return EventService.getSubmissionFormById(this.props.eventId)
        .then((res: any) => {
          if (Object.keys(res.data.message.template).length === 0) {
            task_data = [];
          } else {
            task_data = res.data.message.template.task_data;
          }
          return Promise.resolve(task_data);
        })
        .catch((error: any) => {
          console.error('error', error);
          return Promise.resolve([]);
        });
    }
    return Promise.resolve([]);
  };

  render() {
    return (
      <div className="App">
        {this.props.currentStep === 2 ? (
          <div id={this.props.currentStep}>
            <DndWrapper id={this.props.currentStep}>
              <ReactFormBuilder onLoad={this.onLoad} onPost={this.onPost} toolbarItems={items} />
            </DndWrapper>
          </div>
        ) : (
          <div id={this.props.currentStep}>
            <DndWrapper id={this.props.currentStep}>
              <ReactFormBuilder onLoad={this.onLoad} onPost={this.onPost} toolbarItems={items} />
            </DndWrapper>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentStep, eventId } = state.rootReducer.EventFormReducer;
  return {
    currentStep,
    eventId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setRegistraionDetail: (data: any) => dispatch(setRegistraionDetail(data)),
    setSubmissionDetail: (data: any) => dispatch(setSubmissionDetail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);
