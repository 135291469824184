import { Step, StepLabel, Stepper } from '@mui/material';

function HorizontalStepper({ currentStep }: any) {
  return (
    <Stepper activeStep={currentStep - 1} alternativeLabel>
      <Step>
        <StepLabel>Basic Details</StepLabel>
      </Step>
      <Step>
        <StepLabel>Registration</StepLabel>
      </Step>
      <Step>
        <StepLabel>Submission</StepLabel>
      </Step>
      <Step>
        <StepLabel>Judgement</StepLabel>
      </Step>
    </Stepper>
  );
}

export default HorizontalStepper;
