import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Login from '../LoginScreen/Login';

import './HomePage.scss';

function HomePage() {
  const auth = useSelector((state: any) => state.rootReducer.AuthReducer);
  if (auth.isLogged) {
    return <Navigate to="dashboard" replace />;
  }
  return (
    <div className="container-fluid p-0 wrapper">
      <div className="background" />
      <Login />
    </div>
  );
}

export default HomePage;
