import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { data } from 'jquery';
import moment from 'moment';

import { SELECT_COMPONENT } from '../../common/constants/displayEventStatus';
import ApprovalModal from '../../components/ApprovalModal/ApprovalModal';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import FormButton from '../../components/FormButton/FormButton';
import SelectComponent from '../../components/Select/SelectComponent';
import { nextStep, setJudgementDetail } from '../../redux/actions/FormAction/FormAction';
import EventService from '../../services/EventService';
import timeconv from '../../utils/timeConversion';

function Judgement() {
  const [count, setCount] = useState(0);
  const [judgelist, setJudgelist] = useState<any[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [cnfModalShow, setCnfModalShow] = useState(false);
  const [criterialist, setCriterialist] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const criteria = useRef<HTMLInputElement | null>(null);
  const maxScore = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const criterialistAPI: any[] = [];
  const { judgementTemplate, eventId, isEditMode } = useSelector(
    (state: any) => state.rootReducer.EventFormReducer,
  );
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const addJudge = (choice: any) => {
    setJudgelist([...judgelist, choice]);
  };

  const removeJudge = (idx: number) => {
    const filter = judgelist.filter((judge, index) => index !== idx);
    setJudgelist(filter);
  };
  const addCriteria = () => {
    if (criteria.current !== null && maxScore.current !== null) {
      if (criteria.current.value !== '' && maxScore.current.value !== '') {
        const data = {
          criteriaName: criteria.current.value,
          maxScore: Number(maxScore.current.value),
        };
        setCriterialist([...criterialist, data]);
        criteria.current.value = '';
        maxScore.current.value = '';
      }
    }
  };
  const removeCriteria = (idx: number) => {
    const filter = criterialist.filter((criteria, index) => index !== idx);
    setCriterialist(filter);
  };

  const onCancel = () => {
    if (judgelist.length !== 0 || data.length !== 0) {
      setCnfModalShow(true);
    }
  };

  const onHandleChange = (item: any) => {
    if (count % 2 !== 0) {
      setShow(false);
    }
    setState(item.selection);
    setCount((prevCount) => prevCount + 1);
  };

  const onSave = () => {
    setLoading(true);
    const localData = {
      eventId,
      startDate: timeconv.formatDateToLocalISOStartDate(state.startDate),
      endDate: timeconv.formatDateToLocalISOEndDate(state.endDate),
      judgeEmail: judgelist,
      data: criterialist,
    };
    EventService.postJudgementData(eventId, localData)
      .then((res: any) => {
        toast.success('Event created successfully');
        dispatch(setJudgementDetail(localData));
        navigate('/');
        dispatch(nextStep());
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error('An error occured while saving your changes');
        console.error(err);
      });
  };
  useEffect(() => {
    if (eventId) {
      EventService.getJudgementDataById(eventId)
        .then((res: any) => {
          const getDate = res.data.message.judgementFormData[0];
          var startDate = new Date(getDate.startDate).toUTCString().slice(5,16);
          var enddate = new Date(getDate.endDate).toUTCString().slice(5,16);
          const DateJson = {
            startDate: new Date(startDate),
            endDate: new Date(enddate),
            key: 'selection',
          };
          res.data.message.criteriaData.map((data: any) => {
            const criteriaJson = {
              criteriaName: data.criteriaName,
              maxScore: data.max_score,
            };
            criterialistAPI.push(criteriaJson);
          });
          setState(DateJson);
          setCriterialist(criterialistAPI);
          setJudgelist(res.data.message.judgeEmail);
          dispatch(
            setJudgementDetail({
              eventId,
              startDate: timeconv.formatDateToLocalISOStartDate(state.startDate),
              endDate: timeconv.formatDateToLocalISOEndDate(state.endDate),
              judgeEmail: judgelist,
              data: criterialist,
            }),
          );
        })

        .catch((err: any) => {
          console.error(err);
        });
    }
    window.scrollTo(0, 0);
  }, [eventId]);
  return (
    <>
      <ApprovalModal show={modalShow} onHide={() => setModalShow(false)} eventId={eventId} />
      <ConfirmModal show={cnfModalShow} onHide={() => setCnfModalShow(false)} />
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Judgement Date Range</Form.Label>
        <div
          className="rdrDateDisplay w-25 ms-0"
          style={{ color: 'rgb(61, 145, 255)' }}
          onClick={() => setShow(!show)}
        >
          <span className="rdrDateInput rdrDateDisplayItem text-center">
            <input
              placeholder="start date"
              value={`${state?.startDate.toLocaleDateString()} - ${state?.endDate.toLocaleDateString()}`}
              type="text"
            />
          </span>
        </div>
        <div className="judgement-daterange-picker">
          {show ? (
            <DateRangePicker
              onChange={(e: any) => onHandleChange(e)}
              moveRangeOnFirstSelection={false}
              showDateDisplay
              months={2}
              ranges={[state]}
              direction="horizontal"
              minDate={moment().toDate()}
            />
          ) : null}
        </div>
      </Form.Group>
      <Form.Group controlId="organizer">
        <Form.Label className="fw-bold">Judge</Form.Label>
        <SelectComponent name={SELECT_COMPONENT.JUDGE} addOrganizer={addJudge} />
      </Form.Group>

      <ul className="p-0 mt-2">
        {judgelist.map((judge, index) => (
          <li
            style={{ backgroundColor: '#0000001A' }}
            className="px-3 py-1 rounded-pill d-inline-block my-2"
            key={index + 1}
          >
            {judge}
            <i
              className="bi bi-x text-danger ms-3"
              aria-hidden="true"
              onClick={(event) => removeJudge(index)}
            />
          </li>
        ))}
      </ul>
      <Row className="w-75 mt-3">
        <Form.Group as={Col}>
          <Form.Label className="fw-bold">Criteria Name</Form.Label>
          <Form.Control
            type="text"
            name="criteria"
            ref={criteria}
            placeholder="Enter Criteria Name"
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label className="fw-bold">Maximum Score</Form.Label>
          <Form.Control
            type="number"
            name="maxScore"
            ref={maxScore}
            placeholder="Enter Maximum Score"
          />
        </Form.Group>
        <Form.Group as={Col} className="align-self-end">
          <Button
            variant="primary"
            type="submit"
            className="px-4 text-white"
            onClick={() => addCriteria()}
          >
            ADD
          </Button>
        </Form.Group>
      </Row>

      <ul className="p-0 mt-2">
        {criterialist.map((criteria, index) => (
          <li
            style={{ backgroundColor: '#0000001A' }}
            className="px-3 py-1 rounded-pill d-inline-block my-2"
            key={index + 1}
          >
            <span className="fw-bold">{criteria.criteriaName}</span>
            <span className="fw-bold ms-2">( {criteria.maxScore} )</span>
            <i
              className="bi bi-x text-danger ms-3"
              aria-hidden="true"
              onClick={(event) => removeCriteria(index)}
            />
          </li>
        ))}
      </ul>
      <FormButton showModal={setModalShow} onSave={onSave} onCancel={onCancel} loading={loading} />
    </>
  );
}

export default Judgement;
