import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { approval } from '../redux/actions/AuthAction/authActions';
import AuthService from '../services/AuthService';

function PrivateRoutes() {
  const dispatch = useDispatch();
  useEffect(() => {
    const result = AuthService.isAdmin();
    result.then((response: any) => {
      if (response.data.message?.roles?.status === 'APPROVER') {
        dispatch(approval(true));
      }
    });
  }, []);
  const auth = useSelector((state: any) => state.rootReducer.AuthReducer);
  return auth.isLogged ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoutes;
