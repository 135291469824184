import { AnyAction } from 'redux';

type EventRoleState = {
  roles: {
    isOrganizer: boolean;
    isJudge: boolean;
    isParticipant: boolean;
    isSubmitted: boolean;
    isSubmissionEnabled: boolean;
  };
};

const initialState: EventRoleState = {
  roles: {
    isOrganizer: false,
    isJudge: false,
    isParticipant: false,
    isSubmitted: false,
    isSubmissionEnabled: false,
  },
};
const EventRoleReducer = (state: EventRoleState = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'ROLE':
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
};

export default EventRoleReducer;
