const DISPLAY_EVENT_STATUS = {
  ONGOING: 'Ongoing',
  APPROVED: 'Approved',
  REQUESTED: 'Waiting For Approval',
  ACTION_REQUIRED: 'Action Required',
  DRAFT: 'Draft',
  DISCARD: 'Discarded',
  APPROVE: 'Approve',
  ANNOUNCED: 'Announced',
  PAST_EVENT: 'Past Event',
  JUDGEMENT: 'Judgement',
  RESULTS: 'Results',
  ARCHIVE: 'Archived',
  UPCOMING: 'Upcoming',
  COMPLETED: 'Completed',
  SUBMITTED: 'Submitted',
  REGISTERED: 'Registered',
  REGISTRATION_STARTED: 'Registration Started',
  REGISTRATION_CLOSED: 'Registration Closed',
  SUBMISSION_STARTED: 'Submission Started',
  SUBMISSION_CLOSED: 'Submission Closed',
};

const ROLE = {
  ORGANIZER: 'ORGANIZER',
  JUDGE: 'JUDGE',
  USER: 'USER',
};

const SELECT_COMPONENT = {
  ORGANIZER: 'organizers',
  JUDGE: 'judges',
};

export { DISPLAY_EVENT_STATUS, ROLE, SELECT_COMPONENT };
