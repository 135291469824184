import { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import './TinyMCE.scss';

declare let tinymce: any;

function TinyMCE(props: any) {
  const [initTiny, setInitTiny] = useState<any>({
    menubar: false,
    height: 250,
    image_title: true,
    automatic_uploads: true,
    file_picker_types: 'image file media',
    file_picker_callback(cb: any, value: any, meta: any) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.onchange = function () {
        if (input.files != null) {
          const file = input.files[0];
          const reader = new FileReader();
          reader.onload = function () {
            const id = `blobid${new Date().getTime()}`;
            const { blobCache } = tinymce.activeEditor.editorUpload;
            const base64 = (reader.result as string).split(',')[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        }
      };
      input.click();
    },
    plugins: [
      'link',
      'table',
      'image',
      'link',
      'lists',
      'advlist',
      'autolink',
      'lists',
      'link',
      'searchreplace',
      'visualblocks',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'wordcount',
    ],
    toolbar:
      'undo redo | fontsize fontfamily| bold italic underline strikethrough image| alignleft aligncenter alignright alignjustify |bullist numlist | forecolor backcolor removeformat |link table |',
  });
  return (
    <Editor
      onInit={(evt, editor) => {
        props.editorRef.current = editor;
      }}
      initialValue={props.description}
      init={initTiny}
      tinymceScriptSrc="/tinymce/tinymce.min.js"
    />
  );
}
export default TinyMCE;
