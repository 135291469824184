import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import FormButton from '../../components/FormButton/FormButton';
import FormGenerator from '../../components/FormGenerator/FormGenerator';
import Toggle from '../../components/ToggleSwitch/Toggle';
import { nextStep, setSubmissionDetail } from '../../redux/actions/FormAction/FormAction';
import EventService from '../../services/EventService';
import timeconv from '../../utils/timeConversion';

import '../Registration/Registration.scss';

function Submission() {
  const [count, setCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const { submissionTemplate, eventId, startDate, endDate, isEditMode } = useSelector(
    (state: any) => state.rootReducer.EventFormReducer,
  );
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const onCancel = () => {
    if (Object.keys(submissionTemplate).length !== 0) {
      setModalShow(true);
    }
  };

  const onHandleChange = (item: any) => {
    if (count % 2 !== 0) {
      setShow(false);
    }
    setState(item.selection);
    setCount((prevCount) => prevCount + 1);
  };

  const onSave = () => {
    let submissionAnswers = {};
    if (!toggle) {
      submissionAnswers = {
        eventId,
        template: {},
        isEnabled: false,
      };
      dispatch(setSubmissionDetail({ isEnabled: false, template: {} }));
    } else {
      dispatch(
        setSubmissionDetail({
          ...submissionTemplate,
          startDate: timeconv.formatDateToLocalISOStartDate(state.startDate),
          endDate: timeconv.formatDateToLocalISOEndDate(state.endDate),
        }),
      );
      submissionAnswers = {
        ...submissionTemplate,
        startDate: timeconv.formatDateToLocalISOStartDate(state.startDate),
        endDate: timeconv.formatDateToLocalISOEndDate(state.endDate),
        isMultipleSubmissionEnabled : true,
      };
      setLoading(true);

      if (toggle) {
        if (Object.keys(submissionTemplate).length === 0) {
          // toast.error("Create and Save the Form");
          // return;
          // TODO: Add appropriate toasters to show form has to be constructed
        }
      } else {
        dispatch(setSubmissionDetail({ isEnabled: 'false' }));
      }
    }

    EventService.updateSubmissionForm(eventId, submissionAnswers)
      .then((response: any) => {
        setLoading(false);
        dispatch(nextStep());
        toast.success('Successfully Created Submission Form');
      })
      .catch((error: any) => {
        toast.error('An error occured while saving your data, Please contact Admin');
        console.error('error', error);
      });
  };

  useEffect(() => {
    if (Object.keys(submissionTemplate).length !== 0 || isEditMode) {
      EventService.getSubmissionFormById(eventId)
        .then((res: any) => {
          if (Object.keys(res.data.message.template).length === 0) {
            setToggle(false);
          } else {
            var startDate = new Date(res.data.message.startDate).toUTCString().slice(5,16);
            var enddate = new Date(res.data.message.endDate).toUTCString().slice(5,16);
            const dateUpdate = {
              startDate: new Date(startDate),
              endDate: new Date(enddate),
              key: 'selection',
            };
            setState(dateUpdate);
          }
          window.scrollTo(0, 0);
          return Promise.resolve(res.data.message.template.task_data);
        })
        .catch((error: any) => {
          console.error('error', error);
          window.scrollTo(0, 0);
          return Promise.resolve([]);
        });
    } else if (submissionTemplate.isEnabled === false) {
      setToggle(false);
    }
  }, [setState, setToggle, eventId]);

  return (
    <div>
      <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
      <span className="me-2">Submission template</span>
      <Toggle ischeck={toggle} setToggle={setToggle} />
      <div>
        {toggle ? (
          <>
            <Form.Group className="my-3">
              <Form.Label className="me-2 mb-0">Submission Date Range</Form.Label>
              <div
                className="rdrDateDisplay w-25 ms-0"
                style={{ color: 'rgb(61, 145, 255)' }}
                onClick={() => setShow(!show)}
              >
                <span className="rdrDateInput rdrDateDisplayItem text-center">
                  <input
                    placeholder="start date"
                    value={`${state?.startDate.toLocaleDateString()} - ${state?.endDate.toLocaleDateString()}`}
                    type="text"
                  />
                </span>
              </div>
              <div className="submission-daterange-picker">
                {show ? (
                  <DateRangePicker
                    onChange={(e: any) => onHandleChange(e)}
                    moveRangeOnFirstSelection={false}
                    showDateDisplay
                    months={2}
                    ranges={[state]}
                    direction="horizontal"
                    minDate={moment().toDate()}
                  />
                ) : null}
              </div>
            </Form.Group>
            <FormGenerator isSubmission={toggle} />
            <FormBuilder isSubmission={toggle} />
          </>
        ) : null}
        <FormButton onSave={onSave} onCancel={onCancel} loading={loading} />
      </div>
    </div>
  );
}
export default Submission;
