import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import logo from '../assets/presidio-eventbuzz-logo.png';
import { logout } from '../redux/actions/AuthAction/authActions';

function Navbar() {
  const dispatch = useDispatch();
  const navLinkStyles = ({ isActive }: any) => {
    return {
      width: 'max-content',
      fontSize: '18px',
      fontWeight: isActive ? 'bold' : 'normal',
      borderBottom: isActive ? '3px solid #00adf0' : '3px solid white',
      color: isActive ? '#00adf0' : '#000000',
    };
  };
  const { isLogged, user, isAdmin } = useSelector((state: any) => state.rootReducer.AuthReducer);
  const storage = localStorage.getItem('login');
  let name = '';
  let email = '';
  if (storage) {
    const data = JSON.parse(storage);
    name = data.payload.name;
    email = data.payload.email;
  }

  const logoutHandler = async () => {
    localStorage.clear();
    dispatch(logout());
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-white px-4 py-md-0 align-items-center">
      <div className="container-fluid">
        <Link to="/">
          <span className="navbar-brand py-md-3">
            <img className="nav-logo" src={logo} alt="logo" />
          </span>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
        >
          <i className="bi bi-list text-dark fw-bold" />
        </button>
        <div className="collapse navbar-collapse align-items-end" id="navbarToggler">
          <div className="navbar-nav">
            {isLogged ? (
              <NavLink
                to="/dashboard"
                style={navLinkStyles}
                className="p-2 ms-0 ms-md-5 d-inline py-md-3"
              >
                Dashboard
              </NavLink>
            ) : (
              ''
            )}
          </div>
          <div className="navbar-nav">
            {isLogged ? (
              <NavLink
                to="/my-events"
                style={navLinkStyles}
                className="p-2 ms-0 ms-md-5 d-inline py-md-3"
              >
                My Events
              </NavLink>
            ) : (
              ''
            )}
          </div>
          <div className="navbar-nav">
            {isLogged && isAdmin ? (
              <NavLink
                to="/requests"
                style={navLinkStyles}
                className="p-2 ms-0 ms-md-5 d-inline py-md-3"
              >
                Requests
              </NavLink>
            ) : (
              ''
            )}
          </div>
          <div className="ms-auto order-1 order d-flex align-items-center navbar-right-items">
          {isLogged ? (
              <div className="p-2 py-md-3 d-flex flex-column text-md-end user_info">
                <span>{name}</span>
                <span>{email}</span>
              </div>
               ) : (
                <h5 className="nav_name mb-0 py-3">PRESIDIO</h5>
            )}
            {isLogged ? (
              <div className="py-md-3 ms-auto">
                <button
                  type="button"
                  onClick={logoutHandler}
                  className="btn outline btn-outline-primary ms-md-4 text-primary px-5"
                >
                  Logout
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
