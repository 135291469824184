/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const baseUrl = process.env.REACT_APP_Backend_Url;
const stage = process.env.REACT_APP_STAGE;

enum Deployment {
  dev = 'dev',
  test = 'test',
  local = 'local',
  prod = 'prod'
}

const getBaseUrl = (stage: any) => {
  switch (stage) {
    case Deployment.local:
      return `localhost:3000`;
    case Deployment.dev:
      return `eventbuzz.dev.app.presidio.com`;
    case Deployment.prod:
      return `eventbuzz.presidio.com`;
    default:
      return `eventbuzz.dev.app.presidio.com`;
  }
};
const awsConfig = {
  auth: {
    plugins: {
      awsCognitoAuthPlugin: {
        IdentityManager: {
          Default: {},
        },
        CredentialsProvider: {
          CognitoIdentity: {
            Default: {
              PoolId: stage === 'prod'? 'us-west-2_UdPqWn4Tu': 'us-west-2_C6HJgFzjQ',
              Region: 'us-west-2',
            },
          },
        },
        CognitoUserPool: {
          Default: {
            PoolId: stage === 'prod'? 'us-west-2_UdPqWn4Tu': 'us-west-2_C6HJgFzjQ',
            AppClientId: stage === 'prod'? '3shfm6i0ccasmvc2qd7742h9e3' : '2ha8hrt28jk10o3fo42ut2diu1',
            Region: 'us-west-2',
          },
        },
      },
    },
  },
  Auth: {
    region: 'us-west-2',
    userPoolId: stage === 'prod'? 'us-west-2_UdPqWn4Tu':'us-west-2_C6HJgFzjQ',
    userPoolWebClientId: stage === 'prod'? '3shfm6i0ccasmvc2qd7742h9e3' :'2ha8hrt28jk10o3fo42ut2diu1',
    authenticationFlowType: 'CUSTOM_AUTH',
    clientMetadata: { myCustomKey: 'myCustomValue' },
    oauth: {
      domain: stage === 'prod' ? 'eventbuzz-prod.auth.us-west-2.amazoncognito.com' : 'eventbuzz-dev.auth.us-west-2.amazoncognito.com',
      scope: ['email', 'openid', 'phone', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn:
        stage === 'local'
          ? `http://${getBaseUrl(stage)}/dashboard`
          : `https://${getBaseUrl(stage)}/dashboard`,
      redirectSignOut:
        stage === Deployment.local ? `http://${getBaseUrl(stage)}` : `https://${getBaseUrl(stage)}`,
      responseType: 'code',
    },
  },
  awsmobile: {
    aws_project_region: 'us-west-2',
  },
  API: {
    endpoints: [
      {
        name: stage === 'prod' ? 'event-buzz-prod' : 'event-buzz-dev',
        endpoint: baseUrl,
      },
    ],
  },
};
export default awsConfig;
