import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import TableComponent from '../../components/Table/Table';
import EventService from '../../services/EventService';

function ViewRegisteration() {
  const [data, setData] = useState<any[]>([]);
  const [fileName, setFileName] = useState('');
  const { eventId } = useParams();

  const fetchData = useCallback(async () => {
    const response = await EventService.getAllEventRegistrations(eventId);

    for (const index in response.data.message) {
      const dateTime = new Date(response.data.message[index].registerAt).toLocaleDateString(
        'en-IN',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        },
      );
      response.data.message[index].data.forEach((fileid: any) => {
        if (Object.keys(fileid).includes('fileId')) {
          fileid = JSON.stringify(fileid).split(':')[1];
          fileid = fileid.slice(1, fileid.length - 2);
          response.data.message[index].data =
            `${process.env.REACT_APP_downlodFileUrl}${eventId}/${fileid}`;
        }
      });
      response.data.message[index].registerAt = dateTime;
      setFileName(`${response.data.message[index].eventName}-Registration.csv`);
    }
    setData(response.data.message);
  }, [eventId]);

  const headers = useMemo(
    () => [
      { label: 'Registered Time', key: 'registerAt' },
      { label: 'Participant', key: 'username' },
      { label: 'Email', key: 'userEmail' },
      { label: 'Data', key: 'data' },
    ],
    [],
  );

  useEffect(() => {
    fetchData();
  }, [eventId]);

  const columns = useMemo(
    () => [
      {
        Header: 'Participant',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'userEmail',
        Cell: (cell: any) => <div className="lowercase">{cell.value}</div>,
      },
      {
        Header: 'Registered Time',
        accessor: 'registerAt',
        Cell: (cell: any) => <div className="text-lowercase">{cell.value}</div>,
      },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: (cell: any) => (
          <div>
            <button type="button" className="btn btn-outline-primary">
              <Link to={`/registration/${eventId}/${cell.row.values.id}`}>
                <span
                  id="boot-icon"
                  className="bi bi-eye-fill text-white"
                  style={{
                    fontSize: '1rem',
                    color: 'rgb(35, 198, 231)',
                  }}
                />
              </Link>
            </button>
          </div>
        ),
      },
    ],
    [eventId],
  );

  return <TableComponent data={data} columns={columns} headers={headers} filename={fileName} />;
}
export default ViewRegisteration;
