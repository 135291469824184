import { ReactFormGenerator } from 'react-form-builder2';

function FormModel({ prevData }: any) {
  return (
    <div>
      {prevData.length === 0 ? (
        <h1>Please Create a Form</h1>
      ) : (
        <div>
          <ReactFormGenerator data={prevData} hide_actions />
        </div>
      )}
    </div>
  );
}

export default FormModel;
