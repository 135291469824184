import authTypes from './authTypes';

export const login = (): any => {
  return {
    type: authTypes.LOGGED_IN,
  };
};

export const approval = (isAdmin: boolean) => {
  return {
    type: authTypes.IS_ADMIN,
    payload: isAdmin,
  };
};
export const isLoading = () => {
  return {
    type: authTypes.IS_LOADING,
  };
};

export const logout = () => {
  return {
    type: authTypes.LOGGED_OUT,
  };
};
