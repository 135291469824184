import { Step, StepLabel, Stepper } from '@mui/material';

const stepperStyles = {
  '.MuiStepLabel-root': {
    padding: '0',
  },
  '.MuiStepLabel-iconContainer': {
    order: 2,
    position: 'relative',
    top: '1px',
    right: '9px',
  },
  '.MuiStepLabel-label': {
    textAlign: 'right',
  },
  '.MuiStepConnector-root': {
    marginLeft: '270px',
  },
  '.MuiStepConnector-line': {
    height: '110px',
    borderLeftStyle: 'dashed',
  },

  '.Mui-active>text': {
    fill: 'black',
  },

  '.MuiStepConnector-root.Mui-disabled': {
    borderLeftWidth: '1px',
    borderLeftStyle: 'dashed',
    borderLeftColor: 'white',
  },
  '.MuiStepConnector-root.Mui-completed,.MuiStepConnector-root.Mui-active': {
    borderLeftWidth: '1px',
    borderLeftStyle: 'dashed',
    borderLeftColor: '#17BF38',
  },
};

function VerticalStepper({ currentStep }: any) {
  return (
    <Stepper activeStep={currentStep - 1} orientation="vertical" sx={stepperStyles}>
      <Step>
        <StepLabel>
          <h6 className="m-0 me-4">Basic details</h6>
          <span className="form-text me-4">Fill in basic details of the event</span>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>
          <h6 className="m-0 me-4">Registration</h6>
          <span className="form-text me-4">Create a template for registration</span>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>
          <h6 className="m-0 me-4">Submission</h6>
          <span className="form-text me-4">Create a template for result</span>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>
          <h6 className="m-0 me-4">Judgement</h6>
          <span className="form-text me-4">Details of judgement team and criteria</span>
        </StepLabel>
      </Step>
    </Stepper>
  );
}

export default VerticalStepper;
