import { Navigate, Route, Routes } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import ProtectedRoutes from './PrivateRoutes';
import Judged from '../components/Judged/Judged';
import Organized from '../components/Organized/Organized';
import Registered from '../components/Registered/Registered';
import ApproverDashboard from '../screens/ApprovalDashboard/ApproverDashboard';
import Dashboard from '../screens/Dashboard/Dashboard';
import DownloadFile from '../screens/DownloadFile/DownloadFile';
import EventDetails from '../screens/Event/EventDetails';
import RegistrationForm from '../screens/Event/RegistrationForm/RegistrationForm';
import SubmissionForm from '../screens/Event/SubmissionForm/SubmissionForm';
import RegistrationAnswers from '../screens/EventAnswers/RegistrationAnswers/RegistrationAnswers';
import SubmissionAnswers from '../screens/EventAnswers/SubmissionAnswers/SubmissionAnswers';
import EventForm from '../screens/EventForm/EventForm';
import HomePage from '../screens/HomeScreen/HomePage';
import LeadBoardDetails from '../screens/LeaderBoardDetails/LeaderBoardDetails';
import MyEvents from '../screens/MyEvents/MyEvents';
import ScoreBoardDetails from '../screens/ScoreBoardDetails/ScoreBoardDetails';
import ViewRegisteration from '../screens/ViewRegisteration/ViewRegisteration';
import ViewSubmission from '../screens/ViewSubmission/ViewSubmission';
import ViewUserDetails from '../screens/ViewUserDetails/ViewUserDetails';

function AppRouter() {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="my-events" element={<MyEvents />}>
          <Route path="" element={<Navigate to="registered" />} />
          <Route path="registered" element={<Registered />} />
          <Route path="organized" element={<Organized />} />
          <Route path="judged" element={<Judged />} />
        </Route>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="create" element={<EventForm />} />
        <Route path="event/:eventId" element={<EventDetails />} />
        <Route path="approver/:eventId" element={<EventDetails />} />
        <Route path="edit/:eventId" element={<EventForm />} />
        <Route path="view/:eventId" element={<EventForm />} />
        <Route path="registration/:eventId" element={<RegistrationForm />} />
        <Route path="submission/:eventId" element={<SubmissionForm />} />
        <Route path="registration/:eventId/:Id" element={<RegistrationAnswers />} />
        <Route path="submission/:eventId/:Id" element={<SubmissionAnswers />} />

        <Route path="viewSubmission/:eventId" element={<ViewSubmission />} />
        <Route path="event/:eventId/entries" element={<ViewUserDetails />}>
          <Route path="" element={<Navigate to="allRegistrations" />} />
          <Route path="allRegistrations" element={<ViewRegisteration />} />
          <Route path="allSubmissions" element={<ViewSubmission />} />
          <Route path="leaderboard" element={<LeadBoardDetails />} />
          <Route path="scoreboard" element={<ScoreBoardDetails />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="requests" element={<ApproverDashboard />} />
        </Route>
      </Route>
      <Route path="" element={<HomePage />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="download/:eventId/:key/" element={<DownloadFile />} />
    </Routes>
  );
}

export default AppRouter;
