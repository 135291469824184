import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { reset } from '../../redux/actions/FormAction/FormAction';

import './Buttons.scss';

function ViewForm(props: any) {
  const { id } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createCall = () => {
    dispatch(reset());
    navigate(`/view/${id}`);
  };

  return (
    <div className="button-text text-start">
      <button
        type="button"
        className={`btn btn-primary text-color bg-white w-100 text-start ${
          props.state ? 'border-0' : 'border-primary'
        } `}
        onClick={createCall}
      >
        <i className="bi bi-justify pe-2" />
        View Form
      </button>
    </div>
  );
}

export default ViewForm;
