import React from 'react';

import './TopThreeScores.scss'; // Ensure you import your SCSS file

interface LeaderboardData {
  userName: string;
  totalScore: number;
  rank: number;
}

interface PodiumProps {
  topRanks: LeaderboardData[];
  showScores?: boolean;
}

const TopThreeScores: React.FC<PodiumProps> = ({ topRanks, showScores }) => {
  return (
    <div className="podium-container">
      {topRanks.map((rankData) => (
        <div
          className={`podium ${rankData.rank === 1 ? 'gold' : rankData.rank === 2 ? 'silver' : 'bronze'}`}
          key={rankData.rank}
        >
          <div className={`hexagon rank`}>{rankData.rank}</div>
          <div className="user-name">{rankData.userName}</div>
          {showScores && <div className="score">{rankData.totalScore}</div>}
        </div>
      ))}
    </div>
  );
};

export default TopThreeScores;
