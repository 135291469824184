import { Link } from 'react-router-dom';

function ViewLeaderBoard(props: any) {
  const { eventId } = props;
  const style = {};
  return (
    <Link
      to={`/event/${eventId}/entries/leaderboard`}
      className="btn btn-sm outline btn-outline-primary text-uppercase text-primary ms-1"
      style={style}
    >
      <i className="bi bi-pencil-square" />
      <span>&nbsp; View Leader Board</span>
    </Link>
  );
}
export default ViewLeaderBoard;
