import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import ApproverService from '../../services/ApproverService';

function ApprovalModal(props: any) {
  // const {
  //   basicDetail: { id },
  // } = useSelector((state: any) => state.rootReducer.EventFormReducer);
  const handleSubmit = () => {
    ApproverService.sentForApproval(props.eventId).then((response: any) => {
      toast.success('Sent for Approval');
    });
    props.onHide();
  };
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="text-center py-4">
        <p className="fw-bold">Are you sure want to submit for approval?</p>
        <div className="mt-3">
          <Button variant="secondary" className="px-4 me-2 text-white" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" className="px-4 text-white" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ApprovalModal;
