import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';

import { login } from '../../redux/actions/AuthAction/authActions';
import AuthService from '../../services/AuthService';

import './Login.scss';

function Login() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const submit = () => {
    AuthService.fedratedUser();
    setLoading(true);
  };

  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        const obj = JSON.parse(JSON.stringify(data));
        localStorage.setItem('login', JSON.stringify(obj.idToken));
        localStorage.setItem('Jwt', JSON.stringify(obj.accessToken.jwtToken));
        dispatch(login());
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <div id="login_container" className="bg-white p-5 s rounded text-center">
      <h4>Welcome to EventBuzz!</h4>
      <p>Login with your Presidio credentials to get started</p>
      <button
        type="button"
        onClick={submit}
        disabled={loading}
        className="btn btn-primary mt-3 text-white fw-bold"
      >
        Login with Presidio SSO
        {loading ? (
          <span className="ms-2 spinner-border spinner-border-sm text-white" role="status" />
        ) : null}
      </button>
    </div>
  );
}

export default Login;
