import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import HorizontalStepper from '../../components/Stepper/HorizontalStepper/HorizontalStepper';
import VerticalStepper from '../../components/Stepper/VerticalStepper/VerticalStepper';
import BasicDetails from '../../containers/BasicDetails/BasicDetails';
import Judgement from '../../containers/Judgement/Judgement';
import Registration from '../../containers/Registration/Registration';
import Submission from '../../containers/Submission/Submission';
import { setID, switchMode } from '../../redux/actions/FormAction/FormAction';

import './EventForm.scss';

function EventForm() {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const view = pathname.includes('/view/');
  const { currentStep } = useSelector((state: any) => state.rootReducer.EventFormReducer);
  const isEditMode = !!eventId;

  const showForm = (step: number) => {
    switch (step) {
      case 1:
        return <BasicDetails />;
      case 2:
        return <Registration />;
      case 3:
        return <Submission />;
      case 4:
        return <Judgement />;

      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(setID(eventId));
    dispatch(switchMode(isEditMode));
  }, []);

  return (
    <div className="p-3 event-create">
      <div className="bg-white px-4 py-2 wrapper___box">
        <div className="d-flex justify-content-between align-items-center form__nav-bar">
          <h5 className="m-0 fw-bolder">
            {isEditMode && pathname.includes('/edit/')
              ? 'Edit event'
              : view
                ? 'View event'
                : 'Create new event'}
          </h5>
        </div>
        <div className="m-5">
          <div className="row">
            <div className="col-12 col-lg-3 text-end mb-5 mb-lg-0 p-0 pt-5">
              <div className="stepper-div d-none d-lg-block ">
                <VerticalStepper currentStep={currentStep} />
              </div>
              <div className="horizontal-Stepper d-block d-lg-none">
                <HorizontalStepper currentStep={currentStep} />
              </div>
            </div>
            <div className="col-12 col-lg-8 ms-lg-5">
              <div>{showForm(currentStep)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventForm;
