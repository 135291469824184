import React from 'react';

function FilterData({ filter, setFilter }: any) {
  return (
    <div className="container-2">
      <div className="searchText input-group d-flex justify-content-between bg-white">
        <div className="position-relative">
          <i className="bi bi-search position-absolute" />
          <input
            className="bg-light"
            value={filter || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search For Participants"
          />
        </div>

        <button type="button" className="btn btn-outline-primary export">
          Export
        </button>
      </div>
    </div>
  );
}
export default FilterData;
