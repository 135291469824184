import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { reset } from '../../redux/actions/FormAction/FormAction';

function ConfirmModal({ show, onHide }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => {
    dispatch(reset());
    onHide();
    navigate('/');
  };
  return (
    <Modal show={show} onHide={onHide} className="text-center">
      <Modal.Header closeButton>
        <Modal.Title className="h5">Unsaved Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-start">
        <p>It seems like you haven’t saved the changes and it might get lost.</p>
        <p> Are you sure you want to leave the page?</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end pt-1">
        <Button variant="secondary" className="text-white" onClick={goBack}>
          Leave
        </Button>
        <Button variant="primary" onClick={onHide} className="text-white">
          Stay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
