import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select';

import { SELECT_COMPONENT } from '../../common/constants/displayEventStatus';
import useDebounce from '../../hooks/useDebounce';
import adSupport from '../../services/ADService';

import './select-component.scss';

const getName = (data: any) => {
  let fullName;
  let initials = '';

  if (typeof data.label === 'string') {
    fullName = data.label.split(' ');
    if (fullName.length >= 2) {
      initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    } else {
      initials = fullName.shift().charAt(0);
    }
    return initials;
  }
  return '';
};

const preview = (data: any) => ({
  ':before': {
    textAlign: 'center',
    position: 'relative',
    display: 'inline-block',
    borderRadius: '50%',
    content: `'${getName(data)}'`,
    marginRight: '5px',
    fontSize: '12px',
    color: 'white',
    width: '25px',
    height: '25px',
    padding: '2.6px',
  },
});

function SelectComponent(props: any) {
  const { addOrganizer, name, alert } = props;
  const [defaultValue, setDefaultValue] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState<any>(null);
  const [userLoading, setUserLoading] = useState(false);
  const [judgeError, setJudgeError] = useState<any>(null);
  const debounceValue = useDebounce(value, 500);

  const customeStyles = {
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      display: 'none',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      width: '65%',
      border: `1px solid ${alert}`,
      boxShadow: 'none',
      '&:hover': {
        border: `1px solid ${alert}`,
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '65%',
      backgroundColor: 'white',
    }),

    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isFocused ? '#D8F2FD' : 'white',
      color: 'black',
      ...preview(state),
    }),
  };

  const { basicDetail, isEditMode, judgementTemplate } = useSelector(
    (state: any) => state.rootReducer.EventFormReducer,
  );

  const placeholder = `Choose the ${name}`;

  const getUsers = (choice: any) => {
    if (choice !== '') {
      setUserLoading(true);
      adSupport
        .getAdUsers(choice)
        .then((response: any) => {
          const formatOption = response.data.message.users.map((d: any) => {
            return {
              label: d.mail,
              id: d.displayName,
            };
          });
          const filteredOption = formatOption.filter((op: any) => {
            if (op.label !== null) {
              return op;
            }
          });
          setOptions(filteredOption);
          setUserLoading(false);
        })
        .catch((error) => {
          setOptions([]);
        });
    } else {
      setOptions([]);
    }
  };
  const handleChange = (choice: any) => {
    setValue(choice === '' ? null : choice);
    setJudgeError(null);
  };

  const check = (choice: any) => {
    setDefaultValue('');
    const currentUser = choice.label;
    const organizers: string[] = basicDetail.userEmails;

    const judges: string[] = judgementTemplate.userEmails;

    if (
      name === SELECT_COMPONENT.JUDGE &&
      organizers &&
      organizers?.includes(currentUser)
    ) {
      setJudgeError(`${currentUser} is already an Organizer, So cannot be added as Judge`);
      return;
    }

    if (isEditMode && name === SELECT_COMPONENT.ORGANIZER && judges?.includes(currentUser)) {
      // To do
      setJudgeError(`${currentUser} is already an Judge, So cannot be added as Organizer`);
      return;
    }

    addOrganizer(choice.label);
    setOptions([]);
  };

  useEffect(() => {
    getUsers(debounceValue);
  }, [debounceValue]);

  return (
    <div>
      <AsyncSelect
        value={defaultValue}
        placeholder={placeholder}
        options={options}
        styles={customeStyles}
        onChange={check}
        onInputChange={(e) => {
          handleChange(e);
        }}
        isLoading={userLoading}
        menuPosition="fixed"
      />
      {judgeError && (
        <Alert className="judge-alert" key="danger" variant="danger">
          {judgeError}
        </Alert>
      )}
    </div>
  );
}

export default SelectComponent;
