import React, { useEffect, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';

import metroimg from '../../../assets/metroimage.png';
import approvalService from '../../../services/ApprovalService';
import EventService from '../../../services/EventService';
import Spinner from '../../../shared/Loading-Spinner/Spinner';
import logger from '../../../utils/logger';

function RegistrationAnswers() {
  const { eventId, Id } = useParams();
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(null);
  const [username, setUsername] = useState(null);
  const [event, setEvent] = useState<any[]>([]);
  const [answerdata, setAnswerData] = useState<any>(null);
  const [isParticipant, setIsParticipant] = useState(false);
  let user = '';
  async function toDataURL(url: any) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    let finalData = {};
    let answer: any[];

    EventService.getEvent(eventId).then((response: any) => {
      EventService.downloadImage(response.data.message.coverPic).then((res) => {
        setBanner(res.data.img);
      });
    });
    approvalService.getEventUserRole(eventId).then((response) => {
      const { roles } = response.data.message;

      setIsParticipant(roles.isParticipant);
    });
    const getRegistration = async () => {
      const events = await EventService.getRegistrationFormById(eventId)
        .then((res: any) => {
          setEvent(res.data.message.template.task_data);
          return res.data.message.template.task_data;
        })
        .catch((error: any) => {
          toast.error('Something went wrong! Reach out to Admin');
          logger.error('Error on SubmissionAnswers::getRegistrationFormById() - ', error);
        });

      await EventService.getEventRegistrationById(Id)
        .then((res: any) => {
          answer = res.data.message.data;
          user = res.data.message.username;
          setUsername(res.data.message.username);
          res.data.message.data.forEach((datas: any) => {
            const obj = {
              [datas.name]: datas.value,
            };
            finalData = { ...finalData, ...obj };
          });
        })
        .catch(() => {
          toast.error('Something went wrong! Reach out to Admin');
        });
      setAnswerData(finalData);
      setLoading(false);
      setTimeout(() => {
        // To replace general file download button from formGenerator to have custom file download button
        $('.SortableItem').each(function (idx, ele) {
          const templateInfo = events[idx];
          if (templateInfo.element === 'FileUpload') {
            const uid = uuidv4();
            const formGroup = $(ele).children()[0];
            const buttonWrap = $(formGroup).children()[1];
            buttonWrap.innerHTML = `
            <button type="button" class="btn btn-primary text-white mt-1" id="downloadableFile_${uid}">Download File</button>`;
            $(`#downloadableFile_${uid}`).on('click', function () {
              const picId = answer[idx].fileId;
              EventService.getDownloadSignedUrl(picId).then(async (res) => {
                const url = await toDataURL(res.data.message);
                const a: any = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                a.setAttribute('href', url);
                a.setAttribute('target', '_self');
                a.download = 'Download';
                a.click();
                a.remove();
              });
            });
          }
          if (templateInfo.element === 'Camera') {
            const uid = uuidv4();
            const formGroup = $(ele).children()[0];
            const buttonWrap = $(formGroup).children()[1];
            buttonWrap.innerHTML = `
            <button type="button" class="btn btn-primary text-white mt-1" id="downloadableFile_${uid}">Download Image</button>`;
            $(`#downloadableFile_${uid}`).on('click', function () {
              const picId = answer[idx].fileId;
              EventService.getDownloadSignedUrl(picId).then(async (res) => {
                const url = await toDataURL(res.data.message);
                const a: any = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                a.setAttribute('href', url);
                a.setAttribute('target', '_self');
                a.download = `${user}_Registration`;
                a.click();
                a.remove();
              });
            });
          }
        });
      }, 100);
    };
    getRegistration();
  }, []);
  const back = () => {
    if (isParticipant) {
      navigate(-1);
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="p-3 registration_form">
      <div className="bg-white px-5 py-2 wrapper__box">
        <div className="container py-4 ">
          <div className="row">
            <div className="d-inline-block col-4">
              <span onClick={back} role="button" tabIndex={0} className="fw-bold fs-6">
                <i className="bi bi-chevron-left me-2" />
                {isParticipant ? 'Back To Event Details' : 'Back To Event'}
              </span>
            </div>
            <div className="col-4 text-center">
              <h5 className="fw-bolder mb-2 d-inline-block">
                {username ? `${username}'s` : null} Registration
              </h5>
            </div>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="container">
            <div className="banner">
              <img src={banner || metroimg} className="w-100 rounded-top" alt="" height={300} />
            </div>
            <div className="registration_banner pb-2">
              <div className="form_element bg-white mx-auto rounded">
                <div className="border_top bg-primary rounded-top" />
                <div className="px-5 py-2">
                  {answerdata ? (
                    <ReactFormGenerator
                      data={event}
                      action_name="save"
                      answer_data={answerdata}
                      hide_actions
                      read_only
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default RegistrationAnswers;
