import { AnyAction } from 'redux';

import authTypes from '../../actions/AuthAction/authTypes';

type AuthState = {
  isLoading: boolean;
  isLogged: boolean;
  isAdmin: boolean;
};

const initialState: any = {
  isLoading: false,
  isAdmin: false,
  isLogged: !!localStorage.getItem('login'),
};

const AuthReducer = (state: AuthState = initialState, action: AnyAction) => {
  switch (action.type) {
    case authTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case authTypes.IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case authTypes.LOGGED_IN:
      return {
        ...state,
        isLogged: true,
        user: [action.payload],
      };

    case authTypes.LOGGED_OUT:
      return {
        ...state,
        isLogged: false,
      };

    default:
      return state;
  }
};

export default AuthReducer;
