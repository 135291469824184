import { AnyAction } from 'redux';

import {
  BASIC_DETAIL,
  EVENT_ID,
  JUDGEMENT_DETAIL,
  NEXT_STEP,
  PRE_STEP,
  REGISTRATION_DETAIL,
  RESET,
  SUBMISSION_DETAIL,
  SWITCH_MODE,
} from '../../actions/FormAction/FormActionTypes';

const initialState = {
  eventId: null,
  isEditMode: false,
  currentStep: 1,
  basicDetail: {},
  registrationTemplate: {},
  submissionTemplate: {},
  judgementTemplate: {},
};

const EventFormReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case EVENT_ID:
      return {
        ...state,
        eventId: action.payload,
      };
    case NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep === 4 ? 1 : state.currentStep + 1,
      };

    case SWITCH_MODE:
      return {
        ...state,
        isEditMode: action.payload,
      };

    case PRE_STEP:
      return {
        ...state,
        currentStep: state.currentStep <= 1 ? 1 : state.currentStep - 1,
      };

    case BASIC_DETAIL:
      return {
        ...state,
        basicDetail: action.payload,
      };

    case REGISTRATION_DETAIL:
      return {
        ...state,
        registrationTemplate: { ...action.payload },
      };

    case SUBMISSION_DETAIL:
      return {
        ...state,
        submissionTemplate: { ...action.payload },
      };
    case JUDGEMENT_DETAIL:
      return {
        ...state,
        judgementTemplate: { ...action.payload },
      };
    case RESET:
      return {
        ...state,
        isEditMode: false,
        currentStep: 1,
        basicDetail: {},
        registrationTemplate: {},
        submissionTemplate: {},
        judgementTemplate: {},
      };
    default:
      return state;
  }
};

export default EventFormReducer;
