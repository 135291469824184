import React from 'react';
import { Form } from 'react-bootstrap';

import './Toggle.scss';

function Toggle({ ischeck, setToggle }: any) {
  const onToggle = (e: any) => {
    setToggle(e.target.checked);
  };
  return (
    <Form.Check
      type="switch"
      id="custom-switch"
      label="Required"
      checked={ischeck}
      onChange={onToggle}
    />
  );
}

export default Toggle;
