import http from '../utils/HttpUtils';

const getAllUsers = () => {
  return http.get('users/getAll');
};

const getUserByEmail = () => {
  return http.get('users/getByEmail');
};

const createUser = (data: any) => {
  return http.post('users/create', data);
};

const UserService = {
  getAllUsers,
  getUserByEmail,
  createUser,
};
export default UserService;
