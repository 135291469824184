const formatDateToLocalISOStartDate = (date: Date) => {
  const localDate = new Date(date);
  const adjustedDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
  const formattedDate = adjustedDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
  return formattedDate;
};
const formatDateToLocalISOEndDate = (date: Date) => {
  const localDate = new Date(date);
  const adjustedDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
  const formattedDate = adjustedDate.toISOString().split('T')[0] + 'T23:59:59.000Z';
  return formattedDate;
};

const timeconv = {
  formatDateToLocalISOStartDate,
  formatDateToLocalISOEndDate,
};

export default timeconv;
