const GET_EVENTS = 'GET_EVENTS';
const GET_EVENT = 'GET_EVENT';
const CREATE_EVENTS = 'CREATE_EVENTS';
const UPDATE_EVENTS = 'UPDATE_EVENTS';
const DELETE_EVENTS = 'DELETE_EVENTS';
const IS_LOADING = 'IS_LOADING';

const eventTypes = {
  GET_EVENTS,
  GET_EVENT,
  CREATE_EVENTS,
  UPDATE_EVENTS,
  DELETE_EVENTS,
  IS_LOADING,
};

export default eventTypes;
