import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import metroimg from '../../assets/metroimage.png';
import { DISPLAY_EVENT_STATUS } from '../../common/constants/displayEventStatus';
import EventService from '../../services/EventService';

import '../Event/ApproverEvent/ApproverEvent.scss';

function ApprovalActionModal(props: any) {
  const { status, submitapproval, show, onHide } = props;
  const [approvalStatus, setApprovalStatus] = useState(status);
  const [profilePic, setprofilePic] = useState(null);
  const [coverPic, setcoverPic] = useState(null);
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);
  const { event } = props;
  const { name, createdBy } = props.event;

  const startDate = new Date(event.startDate).toDateString().slice(3);
  const endDate = new Date(event.endDate).toDateString().slice(3);

  const submit = () => {
    submitapproval(status, comments);
    setLoading(true);
  };

  useEffect(() => {
    Promise.all([
      EventService.downloadImage(event.coverPic),
      EventService.downloadImage(event.profilePic),
    ]).then((response) => {
      if (typeof response[0].data === 'object') {
        setcoverPic(response[0].data.img);
      }

      if (typeof response[1].data === 'object') {
        setprofilePic(response[1].data.img);
      }
    });
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="approver_event"
    >
      <Modal.Body className="p-0">
        <div className="card">
          <img src={coverPic || metroimg} alt="img" className="card-img-top" height={156} />
          <div className="card-body p-2">
            <div className="profile d-flex">
              <div className="profile_div">
                <img src={profilePic || metroimg} alt="" />
              </div>
            </div>
            <div className="description font-size pb-3 pe-3">
              <div className="d-flex fw-bold justify-content-between pt-2">
                <p className="event-font m-0">{name}</p>
                <p className="p-0">
                  {startDate} - {endDate}
                </p>
              </div>
              <p className="m-0">
                Created by <span className="fw-bold">{createdBy}</span>
              </p>
            </div>
            <div className="dropdown d-flex justify-content-center py-3">
              <select
                className="drop-down-list font-size border border-1 me-3 text-center btn text-dark"
                defaultValue="DEFAULT"
                onChange={(e) => setApprovalStatus(e.target.value)}
              >
                <option hidden value={approvalStatus}>
                  {status}
                </option>
                <option value={DISPLAY_EVENT_STATUS.APPROVED}>Approve</option>
                <option value={DISPLAY_EVENT_STATUS.DISCARD}>Discard</option>
                <option value={DISPLAY_EVENT_STATUS.ACTION_REQUIRED}>Action Required</option>
              </select>
              <textarea
                name="comments"
                id="comments"
                className="p-2 font-size border border-1"
                defaultValue={comments}
                onChange={(e) => setComments(e.target.value)}
                cols={40}
                rows={2}
              />
            </div>
            <div className="d-flex justify-content-evenly p-2 py-3">
              <Button
                className="font-size w-25 rounded-1"
                variant="secondary text-white"
                onClick={props.onHide}
              >
                Cancel
              </Button>
              <Button
                className="font-size w-25 text-white rounded-1"
                variant="info"
                onClick={submit}
              >
                Confirm
                {loading ? (
                  <span
                    className="ms-2 spinner-border spinner-border-sm text-white"
                    role="status"
                  />
                ) : null}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ApprovalActionModal;
