import { useEffect, useState } from 'react';

import { EVENT_STATUS } from '../../common/enums/eventStatus.enum';
import ApproverEvent from '../../components/Event/ApproverEvent/ApproverEvent';
import EventService from '../../services/EventService';
import CreateButton from '../../shared/Buttons/CreateBtn';
import Spinner from '../../shared/Loading-Spinner/Spinner';

import './ApproverDashboard.scss';

function ApproverDashboard() {
  const [requestedEvents, setRequestedEvents] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    EventService.getAllEvents().then((res) => {
      const filteredEvent = res.data.message.filter((event: any) => {
        return event.status === EVENT_STATUS.REQUESTED;
      });
      setRequestedEvents(filteredEvent);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="approval-dashboard p-3">
      <div className="bg-white px-5 py-2 wrapper__box">
        <div className="d-md-flex justify-content-between align-items-center p-2 nav-title">
          <div className="d-md-flex">
            <h5 className="fw-bold p-2 m-0">Requests</h5>
          </div>
          <CreateButton />
        </div>
        {isLoading ? (
          <Spinner />
        ) : requestedEvents.length > 0 ? (
          <div id="events" className="d-flex flex-wrap">
            {requestedEvents.map((event: any) => (
              <ApproverEvent
                key={event.id}
                event={event}
                setRequested={setRequestedEvents}
                events={requestedEvents}
              />
            ))}
          </div>
        ) : (
          <h3 className="text-center heading">No Requests</h3>
        )}
      </div>
    </div>
  );
}

export default ApproverDashboard;
