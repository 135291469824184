import { toast } from 'react-toastify';

import eventTypes from './eventTypes';
import EventService from '../../../services/EventService';

export const isLoading = (data: any) => {
  return {
    type: eventTypes.IS_LOADING,
    payload: data,
  };
};

export function getAllEvents(): any {
  return async (dispatch: any) => {
    try {
      dispatch(isLoading(true));

      const response = await EventService.getAllEvents();
      dispatch({
        type: eventTypes.GET_EVENTS,
        payload: response.data.message,
      });
      dispatch(isLoading(false));
    } catch (error: any) {
      console.error(error);
    }
  };
}

export const getEventById = (id: any): any => {
  return async (dispatch: any) => {
    try {
      const response = await EventService.getEvent(id);
      dispatch({
        type: eventTypes.GET_EVENT,
        payload: response,
      });
    } catch (error: any) {
      toast.error('Something went wrong! Reach out to admin');
      console.error(error);
    }
  };
};

export const deleteEvent = (id: any): any => {
  return async (dispatch: any) => {
    try {
      EventService.deleteEvent(id).then((res) => {
        toast.success('Deleted Event Successfully');
      });
      dispatch({
        type: eventTypes.DELETE_EVENTS,
        payload: id,
      });
    } catch (err: any) {
      toast.error('Something went wrong! Reach out to Admin');
      console.error(err);
    }
  };
};
