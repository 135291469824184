import {
  BASIC_DETAIL,
  EVENT_ID,
  JUDGEMENT_DETAIL,
  NEXT_STEP,
  PRE_STEP,
  REGISTRATION_DETAIL,
  RESET,
  SUBMISSION_DETAIL,
  SWITCH_MODE,
} from './FormActionTypes';

export const nextStep = () => {
  return {
    type: NEXT_STEP,
  };
};

export const prevStep = () => {
  return {
    type: PRE_STEP,
  };
};

export const setID = (id: any) => {
  return {
    type: EVENT_ID,
    payload: id,
  };
};

export const switchMode = (mode: any) => {
  return {
    type: SWITCH_MODE,
    payload: mode,
  };
};

export const setBasicDetail = (data: any) => {
  return {
    type: BASIC_DETAIL,
    payload: data,
  };
};

export const setRegistraionDetail = (data: any) => {
  return {
    type: REGISTRATION_DETAIL,
    payload: data,
  };
};

export const setSubmissionDetail = (data: any) => {
  return {
    type: SUBMISSION_DETAIL,
    payload: data,
  };
};

export const setJudgementDetail = (data: any) => {
  return {
    type: JUDGEMENT_DETAIL,
    payload: data,
  };
};
export const reset = () => {
  return {
    type: RESET,
  };
};
