import pino from 'pino';

const logger = pino({
  level: process.env.REACT_APP_LOGLEVEL,
  timestamp: () => `,"time":"${new Date(Date.now()).toISOString()}"`,
  browser: {
    asObject: true,
  },
});

export default logger;
