import http from '../utils/HttpUtils';

const sentForApproval = (id: string) => {
  return http.get(`event/request/${id}`);
};

const ApproverService = {
  sentForApproval,
};

export default ApproverService;
