const LOGGED_IN = 'LOGGED_IN';
const LOGGED_OUT = 'LOGGED_OUT';
const IS_LOADING = 'IS_LOADING';
const IS_ADMIN = 'IS_ADMIN';

const authTypes = {
  LOGGED_IN,
  LOGGED_OUT,
  IS_LOADING,
  IS_ADMIN,
};

export default authTypes;
