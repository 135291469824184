import { Link } from 'react-router-dom';

function RegisterButton(props: any) {
  const { eventId, isEnable } = props;

  // let style = {};

  // if (!isEnable) {
  //   style = {
  //     background: "#FFEEAF",
  //   };
  // }

  return (
    <Link
      to={`/registration/${eventId}`}
      className="btn btn-sm outline btn-outline-primary text-uppercase text-primary"
    >
      <i className="bi bi-pencil-square" />
      <span>&nbsp; REGISTER</span>
    </Link>
  );
}

export default RegisterButton;
