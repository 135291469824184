import axios from 'axios';

import http from '../utils/HttpUtils';

const updateRegistrationForm = (id: any, payload: any) => {
  return http.post(`registrationForm/update/${id}`, payload);
};

const updateSubmissionForm = (id: any, payload: any) => {
  return http.post(`submissionForm/update/${id}`, payload);
};

const getSubmissionFormById = (id: any) => {
  return http.get(`submissionForm/getByEventId/${id}`);
};
const getRegistrationFormById = (id: any) => {
  return http.get(`registrationForm/getByEventId/${id}`);
};

const getAllEvents = () => {
  return http.get('events');
};

const getEvent = (id: any) => {
  return http.get(`events/getById/${id}`);
};

const createEvent = (data: any) => {
  return http.post('events/create', data);
};

const updateEvent = (id: any, data: any) => {
  return http.post(`events/update/${id}`, data);
};

const deleteEvent = (id: any) => {
  return http.delete(`events/delete/${id}`);
};

const uploadImage = (id: any, payload: any) => {
  return http.post(`events/uploadImage/${id}`, payload);
};
const downloadImage = (id: any) => {
  return http.get(`events/downloadImage/${id}`);
};
const getAllEventRegistrations = (id: any) => {
  return http.get(`registration/getAllEventRegistrtaions/${id}`);
};
const getAllEventSubmissions = (id: any) => {
  return http.get(`submission/getByEventId/${id}`);
};
const approveEvent = (data: any) => {
  return http.post('event/approval', data);
};

const eventApprovalRequest = (id: any) => {
  return http.get(`event/request/${id}`);
};
const postRegistrationData = (data: any) => {
  return http.post('registration/create', data);
};
const postSubmissionData = (data: any) => {
  return http.post('submission/create', data);
};
const getEventRegistrationById = (id: any) => {
  return http.get(`registration/getById/${id}`);
};
const getEventSubmissionById = (id: any) => {
  return http.get(`submission/getById/${id}`);
};

const getsignedUrl = (eventId: any, data: any) => {
  return http.post(`/s3/getUploadFileUrl/${eventId}`, data);
};
const getEventLeaderBoard = (id: any) => {
  return http.get(`/leaderBoard/getEventLeaderBoard/${id}`);
};

const putObjectS3 = (url: any, file: any, type: any) => {
  return axios.put(url, file, {
    headers: {
      'Content-Type': type,
    },
  });
};

const getObjectS3 = (url: any) => {
  return axios.get(url);
};

const getDownloadSignedUrl = (key: any) => {
  return http.get(`/s3/getDownloadFileUrl/${key}`);
};

const postJudgementData = (eventId: any, data: any) => {
  return http.post(`judgementForm/update/${eventId}`, data);
};
const getJudgementDataById = (id: any) => {
  return http.get(`judgementForm/getByEventId/${id}`);
};
const postJudgementScoreData = (data: any) => {
  return http.post(`judgement/createJudgement`, data);
};
const getJudgementScoreData = (id: any) => {
  return http.get(`judgement/getJudgementById/${id}`);
};
const getJudgementBySubmissionIdJudgeId = (id: any) => {
  return http.get(`judgement/getJudgementBySubmissionIdJudgeId/${id}`);
};
const getJudgementByEventWithScore = (id: any) => {
  return http.get(`judgement/getJudgementByEventWithScore/${id}`);
};
const enableLeaderboardForEvent = (id: any) => {
  return http.get(`judge/${id}`);
};
const getJudgementByEventWithScoreAndId = (id: any) => {
  return http.get(`judgement/getJudgementByEventWithScoreAndId/${id}`);
};
const EventService = {
  getAllEvents,
  getEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  getRegistrationFormById,
  getSubmissionFormById,
  updateRegistrationForm,
  updateSubmissionForm,
  approveEvent,
  eventApprovalRequest,
  getEventRegistrationById,
  postSubmissionData,
  uploadImage,
  downloadImage,
  postRegistrationData,
  getAllEventRegistrations,
  getAllEventSubmissions,
  getEventSubmissionById,
  getsignedUrl,
  putObjectS3,
  postJudgementData,
  getJudgementDataById,
  postJudgementScoreData,
  getJudgementScoreData,
  getJudgementBySubmissionIdJudgeId,
  getEventLeaderBoard,
  getJudgementByEventWithScore,
  enableLeaderboardForEvent,
  getJudgementByEventWithScoreAndId,
  getDownloadSignedUrl,
  getObjectS3,
};

export default EventService;
