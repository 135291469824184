import { useEffect, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import metroimg from '../../../assets/metroimage.png';
import { ERROR_CODES } from '../../../common/constants/error-code.constant';
import EventService from '../../../services/EventService';
import Spinner from '../../../shared/Loading-Spinner/Spinner';
import logger from '../../../utils/logger';

import './SubmissionForm.scss';

function SubmissionForm() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [banner, setBanner] = useState(null);
  const [template, setTemplate] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setDataLoading(true);
    EventService.getEvent(eventId).then((response: any) => {
      setName(response.data.message.name);
      Promise.all([
        EventService.downloadImage(response.data.message.coverPic),
        EventService.getSubmissionFormById(eventId),
      ])
        .then((response: any) => {
          setBanner(response[0].data.img);
          setTemplate(response[1].data.message.template.task_data);
          setDataLoading(false);
        })
        .catch(() => {
          setTemplate([]);
          setDataLoading(false);
        });
    });
  }, []);

  const handleSubmit = async (data: any) => {
    setLoading(true);
    console.log(data);
    const promise = data.map((element: any) => {
      if (element.name.includes('file_upload') || element.name.includes('camera')) {
        const filterFromTemplate: any = template?.filter(
          (fields: any) => element?.name === fields?.field_name,
        )?.[0];
        if (filterFromTemplate?.required) {
          if (element.value) {
            const { type } = element.value;
            const body = {
              title: 'event-submission',
              contentType: type,
              type: 'Submission',
            };
            const fileSize: any = process.env.REACT_APP_fileSize;
            if (element.value.size > fileSize) {
              return null;
            }

            return EventService.getsignedUrl(eventId, body).then((res: any) => {
              const { message } = res.data;
              const value = message.split('?', 1);
              const splittedMessage = value[0].split('.com/');
              const key = splittedMessage[1].replaceAll('/', '%2f');
              const reader = new FileReader();
              reader.readAsArrayBuffer(element.value);

              reader.onload = function (e) {
                EventService.putObjectS3(res.data.message, e?.target?.result, type);
              };
              return {
                fileId: key,
              };
            });
          } else {
            return ERROR_CODES.FILE_NOT_UPLOAD;
          }
        }
      }

      return element;
    });
    const formattedData = await Promise.all(promise);
    let flag = 0;
    for (let index = 0; index < formattedData.length; index += 1) {
      if (formattedData[index] === ERROR_CODES.FILE_NOT_UPLOAD) {
        flag = 1;
        setLoading(false);
        toast.error('File not Uploaded');
      }
      if (!formattedData[index]) {
        flag = 1;
        setLoading(false);
        toast.error(` upload file no: ${index + 1}, size should be less than 15mb`);
      }
    }
    if (flag === 0) {
      const submissionAnswers = {
        eventId,
        data: formattedData,
      };
      await EventService.postSubmissionData(submissionAnswers)
        .then(() => {
          toast.success('Your submission has been received');
          navigate(`/event/${eventId}`);
        })
        .catch((err: any) => {
          toast.error('An error occured while saving your changes');
          logger.error('Error on SubmissionForm::postSubmissionData() - ', err.response.data);
        });
    }
  };
  console.log(template);
  return (
    <div className="p-3 submission_form">
      <div className="bg-white px-5 py-2 wrapper__box">
        <div className="container py-4">
          <div className="row">
            <div className="d-inline-block col-4 ">
              <Link to="/" className="fw-bold fs-6">
                <i className="bi bi-chevron-left me-2" />
                Back To Event
              </Link>
            </div>
            <div className="col-4 text-center">
              <h5 className="fw-bolder mb-2 d-inline-block">Submission Form</h5>
            </div>
          </div>
        </div>
        {dataLoading ? (
          <Spinner />
        ) : (
          <div className="container">
            <div className="banner">
              <img src={banner || metroimg} className="w-100" alt="" height={300} />
            </div>
            <div className="pb-2 submission_banner">
              <div className="form_element bg-white mx-auto rounded">
                <div className="border_top bg-primary rounded-top" />
                <div className="px-5 py-2 pb-5">
                  <h2 className="text-center fw-bolder py-3">{name}</h2>
                  {template && template.length > 0 ? (
                    <ReactFormGenerator
                      data={template}
                      hide_actions={false}
                      onSubmit={(e: any) => handleSubmit(e)}
                      submitButton={
                        <button
                          type="submit"
                          className="btn btn-primary ms-auto text-white px-4 py-2"
                          disabled={loading}
                        >
                          Save
                          {loading ? (
                            <span
                              className="ms-2 spinner-border spinner-border-sm text-white"
                              role="status"
                            />
                          ) : null}
                        </button>
                      }
                    />
                  ) : (
                    <h3 className="text-center">Submission Form Not Found</h3>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubmissionForm;
