import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { reset } from '../../redux/actions/FormAction/FormAction';

import './Buttons.scss';

function CreateButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createCall = () => {
    dispatch(reset());
    navigate('/create');
  };
  return (
    <div className="text-end">
      <button type="button" className="btn btn-primary text-white" onClick={createCall}>
        <i className="bi bi-plus-lg fw-bold me-2" />
        Create Event
      </button>
    </div>
  );
}

export default CreateButton;
