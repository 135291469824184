import { useEffect, useState } from 'react';

import { EVENT_STATUS } from '../../common/enums/eventStatus.enum';
import EventService from '../../services/EventService';
import UserService from '../../services/UserService';
import CreateButton from '../../shared/Buttons/CreateBtn';
import Spinner from '../../shared/Loading-Spinner/Spinner';
import logger from '../../utils/logger';
import Events from '../MyEvents/Events';

import './Dashboard.scss';

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [approvedEvents, setRequestedEvents] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    UserService.getUserByEmail()
      .then(() => {
        fetchEvents();
      })
      .catch(() => {
        const storage = localStorage.getItem('login');
        let userName = '';
        let userEmail = '';
        if (storage) {
          const data = JSON.parse(storage);
          userName = data.payload['custom:firstname'] + ' ' + data.payload['custom:lastname'];
          userEmail = data.payload.email;
        }
        const userObj = {
          name: userName,
          email: userEmail,
        };
        UserService.createUser(userObj).then(() => {
          fetchEvents();
        });
      });
  }, []);

  const fetchEvents = () => {
    setIsLoading(true);
    const result = EventService.getAllEvents();
    result
      .then((res) => {
        const filteredEvent = res.data.message.filter((event: any) => {
          return event.status === EVENT_STATUS.APPROVED;
        });
        setRequestedEvents(filteredEvent);
        setIsLoading(false);
      })
      .catch((error) => {
        logger.error(error);
        setIsLoading(false);
      });
  };

  return (
    <div className="p-3 dashboard">
      <div className="bg-white px-5 py-2 wrapper__box">
        <div>
          <div className="d-flex justify-content-between align-items-center p-2 m-2 nav-title">
            <h5 className="fw-bold p-2 m-0 ">All Events</h5>
            <CreateButton />
          </div>
        </div>

        {isLoading && <Spinner />}

        {!isLoading && approvedEvents.length === 0 && (
          <p className="text-center heading py-3">No Events</p>
        )}

        {!isLoading && (
          <div id="events">
            <div className="row mt-3">
              {approvedEvents &&
                approvedEvents.length > 0 &&
                approvedEvents.map((event: any) => <Events key={event.id} event={event} />)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
