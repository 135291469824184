import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Auth } from 'aws-amplify';

import AppRouter from './router/Router';
import Navbar from './shared/Navbar';
import apiInstance from './utils/HttpUtils';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const parseJwt = (token: any) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const refreshSession = async () => {
    const session = await Auth.currentSession();
    const parsedToken = JSON.parse(JSON.stringify(session));
    localStorage.setItem('login', JSON.stringify(parsedToken.idToken));
    localStorage.setItem('Jwt', JSON.stringify(parsedToken.accessToken.jwtToken));
  };

  apiInstance.interceptors.request.use(async (request: any) => {
    try {
      let token = localStorage.getItem('Jwt') || '';
      const decodedJwt = parseJwt(token);
      if ((decodedJwt?.exp ?? 0) * 1000 < Date.now()) {
        await refreshSession();
        token = localStorage.getItem('Jwt') || '';
      }
      request.headers.Authorization = `Bearer ${token.replaceAll('"', '')}`;
      return request;
    } catch (error) {
      console.log(error);
      return request;
    }
  });

  apiInstance.interceptors.response.use((response: any) => {
    // dispatch(isLoading(false));
    return response;
  });

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeOnClick={false}
          closeButton
        />
        <AppRouter />
      </div>
    </BrowserRouter>
  );
}

export default App;
