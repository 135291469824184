import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllEvents } from '../../redux/actions/EventAction/eventActions';
import Events from '../../screens/MyEvents/Events';
import Spinner from '../../shared/Loading-Spinner/Spinner';

function Organized() {
  const { events, isLoading } = useSelector((state: any) => state.rootReducer.EventReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  return (
    <div>
      {isLoading && <Spinner />}

      {!isLoading &&
        events.filter((event: any) => {
          return event.isOrganizer;
        }).length === 0 && <h4 className="text-center heading">No Organized Events</h4>}

      {!isLoading && (
        <div id="events">
          <div className="row mt-3">
            {events &&
              events
                .filter((event: any) => {
                  return event.isOrganizer;
                })
                .map((event: any) => <Events key={event.id} event={event} organized />)}
          </div>
        </div>
      )}
    </div>
  );
}

export default Organized;
