import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import TableComponent from '../../components/Table/Table';
import { useAppSelector } from '../../redux/store';
import EventService from '../../services/EventService';
import Spinner from '../../shared/Loading-Spinner/Spinner';
import JudgeScoreDetails from '../JudgeScoreDetails/JudgeScoreDetails';

function ViewSubmission() {
  const [data, setData] = useState([]);
  const [judgeShow, setJudgeShow] = useState(false);
  const [length, setLength] = useState<any>();
  const { isOrganizer, isJudge } = useAppSelector(
    (state) => state.rootReducer.EventRoleReducer.roles,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');

  const { eventId } = useParams();
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const response = await EventService.getAllEventSubmissions(eventId);
    setLength(response.data.message.length);

    const responseJudge = await EventService.getJudgementDataById(eventId);
    const initialDate = new Date(responseJudge.data.message.judgementFormData[0].startDate);
    const finalDate = new Date(responseJudge.data.message.judgementFormData[0].endDate);
    const presentDate = new Date();

    if (presentDate <= finalDate && presentDate >= initialDate) {
      setJudgeShow(true);
    }
    setLength(response.data.message.length);
    for (const index in response.data.message) {
      response.data.message[index].data.forEach((fileid: any) => {
        if (Object.keys(fileid).includes('fileId')) {
          fileid = JSON.stringify(fileid).split(':')[1];
          fileid = fileid.slice(1, fileid.length - 2);

          response.data.message[index].data =
            `${process.env.REACT_APP_downlodFileUrl}${eventId}/${fileid}`;
        }
      });
      const dateTime = new Date(response.data.message[index].SubmittedAt).toLocaleDateString(
        'en-IN',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        },
      );

      response.data.message[index].SubmittedAt = dateTime;
      setFileName(`${response.data.message[index].eventName}-Submission.csv`);
    }

    setData(response.data.message);
    setIsLoading(false);
  }, [eventId]);

  const headers = useMemo(
    () => [
      { label: 'Email', key: 'mailid' },
      { label: 'Participant', key: 'username' },
      { label: 'Submitted Time', key: 'SubmittedAt' },
      { label: 'Data', key: 'data' },
    ],
    [],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const organizerViewColumns: any = [
    {
      Header: 'Participant',
      accessor: 'username',
    },
    {
      Header: 'Email',
      accessor: 'mailid',
      Cell: (cell: any) => <div className="lowercase">{cell.value}</div>,
    },
    {
      Header: 'Submitted Time',
      accessor: 'SubmittedAt',
      Cell: (cell: any) => <div className="text-lowercase">{cell.value}</div>,
    },
    {
      Header: 'View',
      accessor: 'id',
      Cell: (cell: any) => (
        <div>
          <button type="button" className="btn btn-primary">
            <Link to={`/submission/${eventId}/${cell.row.values.id}`}>
              <span
                id="boot-icon"
                className="bi bi-eye-fill text-white"
                style={{
                  fontSize: '1rem',
                  color: 'rgb(35, 198, 231)',
                }}
              />
            </Link>
          </button>
        </div>
      ),
    },
  ];
  return isLoading ? (
    <Spinner />
  ) : (
    <div>
      {isJudge ? judgeShow && <JudgeScoreDetails /> : !isOrganizer && <Spinner />}
      {isOrganizer && (
        <TableComponent
          data={data}
          columns={organizerViewColumns}
          headers={headers}
          filename={fileName}
        />
      )}
    </div>
  );
}
export default ViewSubmission;
