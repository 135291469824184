import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { prevStep } from '../../redux/actions/FormAction/FormAction';

function FormButton({ onSave, onCancel, showModal, loading, dis, click }: any) {
  const { currentStep, isEditMode } = useSelector(
    (state: any) => state.rootReducer.EventFormReducer,
  );
  const dispatch = useDispatch();

  return (
    <div className="controls mt-4 text-end d-flex d-lg-block">
      <Button type="button" className="px-4 py-1 me-2" variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
      {currentStep !== 1 ? (
        <Button
          type="button"
          className="px-4 py-1 me-2"
          variant="secondary"
          onClick={() => dispatch(prevStep())}
        >
          Back
        </Button>
      ) : null}
      <div onClick={click} className="d-inline">
        <Button
          type="button"
          className="px-4 py-1 text-white"
          variant="primary"
          onClick={onSave}
          disabled={loading || dis}
        >
          <span className="me-1">Save</span>&amp;
          <span className="ms-1">next</span>
          {loading && (
            <span className="ms-2 spinner-border spinner-border-sm text-white" role="status" />
          )}
        </Button>
      </div>

      {currentStep === 4 && isEditMode ? (
        <Button
          type="button"
          className="px-4 py-1 ms-2 text-white"
          variant="primary"
          onClick={() => showModal(true)}
        >
          Send for approval
        </Button>
      ) : null}
    </div>
  );
}

export default FormButton;
