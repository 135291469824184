import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import metroimage from '../../assets/metroimage.png';
import { SELECT_COMPONENT } from '../../common/constants/displayEventStatus';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import FormButton from '../../components/FormButton/FormButton';
import SelectComponent from '../../components/Select/SelectComponent';
import TinyMCE from '../../components/TinyMCE/TinyMCE';
import { nextStep, setBasicDetail, setID } from '../../redux/actions/FormAction/FormAction';
import EventService from '../../services/EventService';
import Spinner from '../../shared/Loading-Spinner/Spinner';
import timeconv from '../../utils/timeConversion';

import 'react-datepicker/dist/react-datepicker.css';
import '../../screens/EventForm/EventForm.scss';

function BasicDetails() {
  const editorRef = useRef<any>(null);
  const [count, setCount] = useState(0);
  const { basicDetail, isEditMode, eventId } = useSelector(
    (state: any) => state.rootReducer.EventFormReducer,
  );
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cover, setcover] = useState<any>(null);
  const [profile, setprofile] = useState<any>(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [organizerlist, setorganizerlist] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [tinyValue, setTinyValue] = useState<any>({ content: '' });
  const dispatch = useDispatch();
  const addOrganizer = (choice: any) => {
    if (!organizerlist.includes(choice)) {
      setorganizerlist([...organizerlist, choice]);
    }
  };
  const removeOrganizer = (idx: number) => {
    const filter = organizerlist.filter((organizer, index) => index !== idx);
    setorganizerlist(filter);
  };

  const handleFileChange = (e: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    if (e.target.name === 'profile') {
      reader.onload = function (e) {
        setprofile(e?.target?.result);
      };
    } else {
      reader.onload = function (e) {
        setcover(e?.target?.result);
      };
    }
  };

  const onCancel = () => {
    let des: any;
    if (description === '<p><br></p>') {
      des = '';
    } else {
      des = description;
    }
    if (
      name !== '' ||
      cover !== null ||
      profile !== null ||
      des !== '' ||
      organizerlist.length !== 0
    ) {
      setModalShow(true);
    } else {
      navigate('/');
    }
  };

  const onHandleChange = (item: any) => {
    if (count % 2 !== 0) {
      setShow(false);
    }
    setState(item.selection);
    setCount((prevCount) => prevCount + 1);
  };

  const onSave = () => {
    setLoading(true);
    const localData = {
      name,
      description: editorRef.current.getContent(),
      coverPic: cover,
      profilePic: profile,
      startDate: timeconv.formatDateToLocalISOStartDate(state.startDate),
      endDate: timeconv.formatDateToLocalISOEndDate(state.endDate),
      userEmails: organizerlist,
    };
    const basicData = {
      name,
      description: editorRef.current.getContent(),
      coverPic: null,
      profilePic: null,
      numberOfParticipants: 0,
      startDate: timeconv.formatDateToLocalISOStartDate(state.startDate),
      endDate: timeconv.formatDateToLocalISOEndDate(state.endDate),
      mode: 'online',
      userEmails: organizerlist,
    };
    if (!isEditMode) {
      if (eventId) {
        EventService.updateEvent(eventId, basicData).then((response: any) => {
          Promise.all([
            EventService.uploadImage(response.data.message.id, {
              data: cover ?? metroimage,
              type: 'coverPic',
            }),
            EventService.uploadImage(response.data.message.id, {
              data: profile ?? metroimage,
              type: 'profilePic',
            }),
          ]).then((response: any) => {
            dispatch(setBasicDetail(localData));
            setLoading(false);
            toast.success('Added Basic details');
            dispatch(nextStep());
          });
        });
      } else {
        EventService.createEvent(basicData)
          .then((res: any) => {
            dispatch(setID(res.data.message.id));
            Promise.all([
              EventService.uploadImage(res.data.message.id, {
                data: cover ?? metroimage,
                type: 'coverPic',
              }),
              EventService.uploadImage(res.data.message.id, {
                data: profile ?? metroimage,
                type: 'profilePic',
              }),
            ]).then((response: any) => {
              dispatch(setBasicDetail(localData));
              setLoading(false);
              toast.success('Added Basic details');
              dispatch(nextStep());
            });
          })
          .catch((err: any) => {
            toast.error('An error occured while saving the changes');
            console.error(err);
          });
      }
    }

    if (isEditMode) {
      EventService.updateEvent(eventId, basicData)
        .then((response: any) => {
          Promise.all([
            EventService.uploadImage(response.data.message.id, {
              data: cover ?? metroimage,
              type: 'coverPic',
            }),
            EventService.uploadImage(response.data.message.id, {
              data: profile ?? metroimage,
              type: 'profilePic',
            }),
          ]).then((response: any) => {
            dispatch(setBasicDetail(localData));
            setLoading(false);
            dispatch(nextStep());
          });
        })
        .catch((error: any) => {
          toast.error('An error occured while saving your changes');
          console.error(error);
        });
    }
  };
  useEffect(() => {
    if (Object.keys(basicDetail).length !== 0) {
      setName(basicDetail.name);
      setDescription(basicDetail.description);
      setcover(basicDetail?.coverPic);
      setprofile(basicDetail?.profilePic);
      setorganizerlist(basicDetail.userEmails);
      const dateUpdate = {
        startDate: new Date(basicDetail.startDate),
        endDate: new Date(basicDetail.endDate),
        key: 'selection',
      };
      setState(dateUpdate);
    }
    if (isEditMode && Object.keys(basicDetail).length === 0) {
      setDataLoading(true);
      EventService.getEvent(eventId).then((response: any) => {
        let newData = response.data.message;
        Promise.all([
          EventService.downloadImage(response.data.message.coverPic),
          EventService.downloadImage(response.data.message.profilePic),
        ])
          .then((value) => {
            newData = {
              ...newData,
              coverPic: value[0].data.img,
              profilePic: value[1].data.img,
            };
            var startDate = new Date(newData.startDate).toUTCString().slice(5,16);
            var enddate = new Date(newData.endDate).toUTCString().slice(5,16);
            const dateUpdate = {
              startDate: new Date(startDate),
              endDate: new Date(enddate),
              key: 'selection',
            };

            setState(dateUpdate);
            setName(newData.name);
            setorganizerlist(newData.userEmails);
            setDescription(newData.description);
            setcover(newData.coverPic);
            setprofile(newData.profilePic);
            setDataLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
        dispatch(setBasicDetail(newData));
      });
    }
    window.scrollTo(0, 0);
  }, [isEditMode, eventId]);

  const checkDis = () => {
    return !(organizerlist.length > 0 && name);
  };

  const alertClick = () => {
    setIsSubmitting(true);
  };
  return dataLoading ? (
    <Spinner />
  ) : (
    <div>
      <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="banner_area mb-4">
        <div className="cover_photo">
          <img src={cover || metroimage} alt="cover" />
          <div className="file-upload rounded">
            <label>
              <i className="bi bi-camera fs-5 text-light" />
              <input
                type="file"
                name="cover"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </label>
          </div>
        </div>
        <div className="profile_photo">
          <img src={profile || metroimage} alt="profile" />
          <div className="file-upload_dp rounded">
            <label>
              <i className="bi bi-camera fs-5 text-light" />
              <input
                type="file"
                name="profile"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </label>
          </div>
        </div>
      </div>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Event Name</Form.Label>
        <Form.Control
          type="text"
          className={`w-50 ${isSubmitting && name === '' ? 'border-danger' : ''}`}
          defaultValue={name}
          name="eventName"
          onChange={(event: any) => setName(event.target.value)}
          placeholder="Enter event name"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Description</Form.Label>
        <TinyMCE editorRef={editorRef} description={description} />
      </Form.Group>

      <Form.Group className="mb-3 date-range">
        <Form.Label className="fw-bold">Event Date Range</Form.Label>
        <div
          className="rdrDateDisplay w-25 ms-0"
          style={{ color: 'rgb(61, 145, 255)' }}
          onClick={() => setShow(!show)}
        >
          <span className="rdrDateInput rdrDateDisplayItem text-center">
            <input
              placeholder="start date"
              value={`${state?.startDate.toLocaleDateString()} - ${state?.endDate.toLocaleDateString()}`}
              type="text"
            />
          </span>
        </div>
        <div className="basicDetails-Date-picker">
          {show ? (
            <DateRangePicker
              onChange={(e: any) => onHandleChange(e)}
              moveRangeOnFirstSelection={false}
              showDateDisplay
              months={2}
              ranges={[state]}
              direction="horizontal"
              minDate={moment().toDate()}
            />
          ) : null}
        </div>
      </Form.Group>

      <Form.Group controlId="organizer">
        <Form.Label className="fw-bold">Organizer</Form.Label>
        <SelectComponent
          name={SELECT_COMPONENT.ORGANIZER}
          addOrganizer={addOrganizer}
          alert={isSubmitting && organizerlist.length === 0 ? '#cc1111' : '#ced4da'}
        />
      </Form.Group>

      <ul className="p-0 mt-2">
        {organizerlist &&
          organizerlist.map((organizer, index) => (
            <li
              style={{ backgroundColor: '#0000001A' }}
              className="px-3 py-1 rounded-pill d-inline-block"
              key={index + 1}
            >
              {organizer}
              <i
                className="bi bi-x text-danger ms-3 pe-auto"
                aria-hidden="true"
                onClick={(event) => removeOrganizer(index)}
              />
            </li>
          ))}
      </ul>
      <div className="basic-details-button">
        <FormButton
          onSave={onSave}
          onCancel={onCancel}
          loading={loading}
          dis={checkDis()}
          click={alertClick}
        />
      </div>
    </div>
  );
}

export default BasicDetails;
