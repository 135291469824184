export const CREATE_FORM = 'CREATE_FORM';
export const EVENT_ID = 'EVENT_ID';
export const NEXT_STEP = 'NEXT_STEP';
export const PRE_STEP = 'PRE_STEP';
export const SWITCH_MODE = 'SWITCH_MODE';
export const BASIC_DETAIL = 'BASIC_DETAIL';
export const REGISTRATION_DETAIL = 'REGISTRATION_DETAIL';
export const SUBMISSION_DETAIL = 'SUBMISSION_DETAIL';
export const JUDGEMENT_DETAIL = 'JUDGEMENT_DETAIL';
export const RESET = 'RESET';
