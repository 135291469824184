import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';

import { ROLE } from '../../common/constants/displayEventStatus';
import { setEventRole } from '../../redux/actions/EventRoleAction/eventRoleActions';
import approvalService from '../../services/ApprovalService';
import Spinner from '../../shared/Loading-Spinner/Spinner';

import './ViewUserDetails.scss';

function ViewUserDetails() {
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [isJudge, setIsJudge] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [tableRole, setTableRole] = useState('');
  const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const back = () => {
    navigate(`/event/${eventId}`);
  };

  let className = 'tab-pane fade show active';
  let userClassName = 'tab-pane fade';

  if (!isOrganizer && !isJudge) {
    [className, userClassName] = [userClassName, className];
  }
  const navStyle = ({ isActive }: any) => {
    return {
      boxShadow: `${isActive ? '#00adf0' : 'transparent'} 0 3px 0`,
      '--tabColor': isActive ? '#00adf0' : 'tranparent',
    };
  };
  useEffect(() => {
    approvalService.getEventUserRole(eventId).then((response) => {
      const { roles } = response.data.message;
      dispatch(setEventRole(roles));
      setIsOrganizer(roles.isOrganizer);
      setIsJudge(roles.isJudge);
      if (roles.isOrganizer) {
        setTableRole(ROLE.ORGANIZER);
      } else if (roles.isJudge) {
        setTableRole(ROLE.JUDGE);
      } else {
        navigate('leaderboard');
        setTableRole(ROLE.USER);
      }
      setIsLoading(false);
    });
  }, [eventId]);

  return (
    <div className="p-3 user-details">
      <div className="mx-3 p-5 bg-white view_user wrapper__box">
        <div className="row mb-5">
          <div className="col-12 col-md-4">
            <span className="fw-bold fs-6" role="button" tabIndex={0} onClick={back}>
              <i className="bi bi-chevron-left me-2" /> Back To Event Details
            </span>
          </div>
          <div className="col-12 col-md-4 mt-3 mt-md-0 text-center">
            <h5 className="fw-bolder mb-2">
              {isOrganizer || isJudge ? 'Event Entries' : 'Leader Board'}
            </h5>
          </div>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={isOrganizer || isJudge ? 'nav-border' : ''}>
              {(isOrganizer || isJudge) && (
                <NavLink
                  to="allRegistrations"
                  className="title-heading title-links"
                  style={navStyle}
                >
                  All Registrations
                </NavLink>
              )}
              {(isOrganizer || isJudge) && (
                <NavLink to="allSubmissions" className="title-heading title-links" style={navStyle}>
                  All Submissions
                </NavLink>
              )}
              {(isOrganizer || isJudge) && (
                <NavLink
                  to="leaderboard"
                  state={tableRole}
                  className="title-heading title-links"
                  style={navStyle}
                >
                  Leader Board
                </NavLink>
              )}
              {isOrganizer && (
                <NavLink to="scoreboard" className="title-heading title-links" style={navStyle}>
                  Score Board
                </NavLink>
              )}
            </div>
            <Outlet />
          </>
        )}
      </div>
    </div>
  );
}
export default ViewUserDetails;
