import { combineReducers } from 'redux';

import AuthReducer from './Auth/AuthReducer';
import EventReducer from './Event/eventReducer';
import EventFormReducer from './EventForm/EventFormReducer';
import EventRoleReducer from './EventRole/eventRoleReducer';

const rootReducer = combineReducers({
  AuthReducer,
  EventReducer,
  EventFormReducer,
  EventRoleReducer,
});

export default rootReducer;
