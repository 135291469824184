import { NavLink, Outlet } from 'react-router-dom';

import CreateButton from '../../shared/Buttons/CreateBtn';

import './my-events.scss';

function MyEvents() {
  const navStyle = ({ isActive }: any) => {
    return {
      boxShadow: isActive ? '0 3px 0 #00adf0' : '0 3px 0 transparent',
      '--tabColor': isActive ? '#00adf0' : 'tranparent',
    };
  };

  return (
    <div className="p-3 bg-muted my-events">
      <div className="bg-white px-5 py-3 content-fit">
        <div className="d-md-flex flex-row-reverse justify-content-between align-items-baseline nav-border">
          <CreateButton />
          <div className="d-flex align-items-center">
            <NavLink to="registered" className="title-heading" style={navStyle}>
              Registered
            </NavLink>
            <NavLink to="organized" className="title-heading" style={navStyle}>
              Organized
            </NavLink>
            <NavLink to="judged" className="title-heading" style={navStyle}>
              Judged
            </NavLink>
          </div>
        </div>

        <Outlet />
      </div>
    </div>
  );
}

export default MyEvents;
