import React from 'react';
import { ElementStore } from 'react-form-builder2';
import { connect } from 'react-redux';

import metroimg from '../../assets/metroimage.png';
import {
  setRegistraionDetail,
  setSubmissionDetail,
} from '../../redux/actions/FormAction/FormAction';
import EventService from '../../services/EventService';
import FormModel from '../FormModel/FormModel';

import './FormGenerator.scss';
import '../../containers/Registration/Registration.scss';

class FormGenerator extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false,
      name: '',
      banner: null,
    };
  }

  componentDidMount() {
    const { eventId } = this.props;
    ElementStore.subscribe((state: { data: any }) =>
      this.setState({
        data: state.data,
      }),
    );
    EventService.getEvent(eventId).then((response: any) => {
      this.setState({
        name: response.data.message.name,
      });
      Promise.all([EventService.downloadImage(response.data.message.coverPic)])
        .then((response: any) => {
          this.setState({
            banner: response[0].data.img,
          });
        })
        .catch((error) => {
          this.setState({});
        });
    });
  }

  showPreview(event: any) {
    event.preventDefault();
    this.setState({
      previewVisible: true,
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false,
    });
  }

  render() {
    let modalClass = 'modal fade';
    if (this.state.previewVisible) {
      modalClass += ' show d-block';
    }

    return (
      <div className="form-generator">
        <button
          type="button"
          className="btn btn-primary text-white float-right"
          style={{ marginRight: '10px' }}
          onClick={this.showPreview.bind(this)}
        >
          Preview Form
        </button>
        {this.state.previewVisible && (
          <div className={modalClass} role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="container">
                  <div className="banner">
                    <i className="bi bi-x-lg float-right" onClick={this.closePreview.bind(this)} />
                    <img
                      src={this.state.banner || metroimg}
                      className="w-100"
                      alt=""
                      height={200}
                    />
                  </div>
                  <div className="pb-2 submission_banner">
                    <div className="form_element bg-white mx-auto rounded">
                      <div className="border_top bg-primary rounded-top" />
                      <div className="px-5 py-2 pb-5">
                        <h2 className="text-center fw-bolder py-3">{this.state.name}</h2>
                        {this.state.data && this.state.data.length > 0 ? (
                          <FormModel prevData={this.state.data} />
                        ) : (
                          <h3 className="text-center">
                            {this.props.currentStep === 2 ? (
                              <>Please Create Registration Form</>
                            ) : (
                              <>Please Create Submission Form</>
                            )}
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  const { currentStep, eventId } = state.rootReducer.EventFormReducer;
  return {
    currentStep,
    eventId,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setRegistraionDetail: (data: any) => dispatch(setRegistraionDetail(data)),
    setSubmissionDetail: (data: any) => dispatch(setSubmissionDetail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormGenerator);
