import { useEffect, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';

import metroimg from '../../../assets/metroimage.png';
import JudgeScoringModal from '../../../components/JudgeModal/JudgeModal';
import approvalService from '../../../services/ApprovalService';
import EventService from '../../../services/EventService';
import Spinner from '../../../shared/Loading-Spinner/Spinner';
import logger from '../../../utils/logger';

import './SubmissionAnswers.scss';

function SubmissionAnswers() {
  const { eventId, Id } = useParams();
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(null);
  const [event, setEvent] = useState<any[]>([]);
  const [username, setUsername] = useState(null);
  const [answerdata, setAnswerData] = useState<any>(null);
  const [scoreShow, setScoreShow] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);
  const [isJudge, setIsJudge] = useState(false);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [isParticipant, setIsParticipant] = useState(false);
  const navigate = useNavigate();
  let user = '';
  async function toDataURL(url: any) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }

  useEffect(() => {
    setLoading(true);
    let finalData = {};
    let answer: any[];
    approvalService.getEventUserRole(eventId).then((response) => {
      const { roles } = response.data.message;
      setIsJudge(roles.isJudge);
      setIsOrganizer(roles.isOrganizer);
      setIsParticipant(roles.isParticipant);
    });
    EventService.getEvent(eventId).then((response: any) => {
      EventService.downloadImage(response.data.message.coverPic).then((res) => {
        setBanner(res.data.img);
      });
    });
    const getSubmission = async () => {
      await EventService.getJudgementDataById(eventId).then((res: any) => {
        const initialDate = res.data.message.judgementFormData[0].startDate;
        const finalDate = res.data.message.judgementFormData[0].endDate;
        const presentDate = new Date().toISOString();
        if (presentDate <= finalDate && presentDate >= initialDate) {
          setButtonShow(true);
        }
      });

      const events = await EventService.getSubmissionFormById(eventId)
        .then((res: any) => {
          setEvent(res.data.message.template.task_data);
          return res.data.message.template.task_data;
        })
        .catch((error: any) => {
          toast.error('Something went wrong! Reach out to Admin');
          logger.error('Error on SubmissionAnswers::getSubmissionFormById(() - ', error);
        });
      await EventService.getEventSubmissionById(Id)
        .then((res: any) => {
          answer = res.data.message.data;
          user = res.data.message.username;
          setUsername(res.data.message.username);
          res.data.message.data.forEach((datas: any) => {
            const obj = {
              [datas.name]: datas.value,
            };
            finalData = { ...finalData, ...obj };
          });
        })
        .catch(() => {
          toast.error('Something went wrong! Reach out to Admin');
        });
      setAnswerData(finalData);
      setLoading(false);
      setTimeout(() => {
        // To replace general file download button from formGenerator to have custom file download button
        $('.SortableItem').each(function (idx, ele) {
          const templateInfo = events[idx];
          if (templateInfo.element === 'FileUpload') {
            const uid = uuidv4();
            const formGroup = $(ele).children()[0];
            const buttonWrap = $(formGroup).children()[1];
            buttonWrap.innerHTML = `
            <button type="button" class="btn btn-primary text-white mt-1" id="downloadableFile_${uid}">Download File</button>`;
            $(`#downloadableFile_${uid}`).on('click', function () {
              const picId = answer[idx].fileId;
              EventService.getDownloadSignedUrl(picId).then(async (res) => {
                const url = await toDataURL(res.data.message);
                const a: any = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                a.setAttribute('href', url);
                a.setAttribute('target', '_self');
                a.download = 'Download';
                a.click();
                a.remove();
              });
            });
          }
          if (templateInfo.element === 'Camera') {
            const uid = uuidv4();
            const formGroup = $(ele).children()[0];
            const buttonWrap = $(formGroup).children()[1];
            buttonWrap.innerHTML = `
            <button type="button" class="btn btn-primary text-white mt-1" id="downloadableFile_${uid}">Download Image</button>`;
            $(`#downloadableFile_${uid}`).on('click', function () {
              const picId = answer[idx].fileId;
              EventService.getDownloadSignedUrl(picId).then(async (res) => {
                const url = await toDataURL(res.data.message);
                const a: any = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                a.setAttribute('href', url);
                a.setAttribute('target', '_self');
                a.download = `${user}_Submission`;
                a.click();
                a.remove();
              });
            });
          }
        });
      }, 100);
    };
    getSubmission();
  }, [buttonShow]);
  const scoreFunction = () => {
    setScoreShow(true);
  };
  const back = () => {
    if (isParticipant) {
      navigate(-1);
    } else {
      navigate(-1);
    }
  };
  return (
    <>
      {scoreShow ? (
        <JudgeScoringModal
          show={scoreShow}
          name={username}
          id={Id}
          onHide={() => {
            setScoreShow(false);
          }}
        />
      ) : null}
      <div className="p-3 submission_form submission_answers">
        <div className="bg-white px-5 py-2 wrapper__box">
          <div className="container py-4 ">
            <div className="row">
              <div className="d-inline-block col-4 ">
                <span onClick={back} role="button" tabIndex={0} className="fw-bold fs-6">
                  <i className="bi bi-chevron-left me-2" />
                  {isParticipant ? 'Back To Event Details' : 'Back To Event'}
                </span>
              </div>
              <div className="col-4 text-center">
                <h5 className="fw-bolder mb-2 d-inline-block">
                  {username ? `${username}'s` : null} Submission
                </h5>
              </div>
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className="container">
              <div className="banner">
                {buttonShow && isJudge && !isOrganizer ? (
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary ms-auto text-white px-2 py-2 float-right buttonshow"
                      onClick={scoreFunction}
                    >
                      <i className="bi bi-card-checklist text-white icon-check me-2" />
                      Add Score
                    </button>
                  </div>
                ) : null}
                <img src={banner || metroimg} className="w-100 rounded-top" alt="" height={300} />
              </div>
              <div className="submission_banner pb-2">
                <div className="form_element bg-white mx-auto rounded">
                  <div className="border_top bg-primary rounded-top" />
                  <div className="px-5 py-2 pb-5">
                    {answerdata ? (
                      <ReactFormGenerator
                        data={event}
                        answer_data={answerdata}
                        read_only
                        hide_actions
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default SubmissionAnswers;
