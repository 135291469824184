import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import metroimg from '../../../assets/metroimage.png';
import { DISPLAY_EVENT_STATUS } from '../../../common/constants/displayEventStatus';
import { EVENT_STATUS } from '../../../common/enums/eventStatus.enum';
import EventService from '../../../services/EventService';
import ApprovalActionModal from '../../ApprovalModal/ApprovalActionModal';

import './ApproverEvent.scss';

function ApproverEvent(props: any) {
  const [status, setStatus] = useState('');
  const [profilePic, setprofilePic] = useState(null);
  const [coverPic, setcoverPic] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const handleShow = () => setModalShow(true);
  const { event, events, setRequested } = props;

  const { id, name, createdBy } = event;

  const startDate = new Date(event.startDate).toDateString().slice(3);
  const endDate = new Date(event.endDate).toDateString().slice(3);

  const handleAction = (status: string) => {
    handleShow();
    setStatus(status);
  };

  const submitApproval = (approvalStatus: any, comments: any) => {
    const req = {
      eventId: id,
      status: approvalStatus.toUpperCase(),
      comments,
    };
    const result = EventService.approveEvent(req);
    result.then((response: any) => {
      if (req.status === 'APPROVE') {
        toast.success('Approved Event');
      } else if (req.status === 'DISCARD') {
        toast.error('Discarded the event');
      } else if (req.status === 'ACTION REQUIRED') {
        toast.warn('Event sent for Action required');
      }
      const pendingEvents = events.filter((e: any) => e.id !== id);
      setRequested(pendingEvents);
      setModalShow(false);
    });
    result.catch((err: any) => {
      toast.error('An Error occured while saving the changes');
    });
  };

  useEffect(() => {
    Promise.all([
      EventService.downloadImage(event.coverPic),
      EventService.downloadImage(event.profilePic),
    ])
      .then((response) => {
        if (typeof response[0].data === 'object') {
          setcoverPic(response[0].data.img);
        }
        if (typeof response[1].data === 'object') {
          setprofilePic(response[1].data.img);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="approver_event">
      <ApprovalActionModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        status={status}
        event={event}
        submitapproval={submitApproval}
      />
      <div className="w-100">
        <div className="card m-2">
          <img src={coverPic || metroimg} alt="img" className="card-img-top" height={156} />
          <div className="card-body p-2 card-body-width">
            <div className="profile d-flex">
              <div className="profile_div">
                <img src={profilePic || metroimg} alt="event profile" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="description font-size">
                <div className="d-flex fw-bold justify-content-between">
                  <p className="event-font">{name}</p>
                  <p className="p-0 mx-1 px-1 m-0">
                    {startDate} - {endDate}
                  </p>
                </div>
                <p className="pt-2 m-0">
                  Created by <span className="fw-bold">{createdBy}</span>
                </p>
              </div>
              <div className="dropdown text-center py-5">
                <button
                  className="btn border border-1 btn-sm dropdown-toggle p-2 text-dark"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Choose an action
                </button>
                <div
                  className="font-size border border-1 dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <button
                    className="dropdown-item text-success"
                    type="button"
                    onClick={() => handleAction(DISPLAY_EVENT_STATUS.APPROVE)}
                  >
                    Approve
                  </button>
                  <button
                    className="dropdown-item text-danger"
                    type="button"
                    onClick={() => handleAction(EVENT_STATUS.DISCARD)}
                  >
                    Discard
                  </button>
                  <button
                    className="dropdown-item text-warning"
                    type="button"
                    onClick={() => handleAction(DISPLAY_EVENT_STATUS.ACTION_REQUIRED)}
                  >
                    Action required
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApproverEvent;
