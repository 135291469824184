import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import JudgeScoringModal from '../../components/JudgeModal/JudgeModal';
import TableComponent from '../../components/Table/Table';
import EventService from '../../services/EventService';
import Spinner from '../../shared/Loading-Spinner/Spinner';

const recordsJudge = [
  {
    userName: '',
    id: '',
    judge: [
      {
        name: 'Judge',
        criteria: [
          {
            criteriaName: 'Criteria Name',
            score: '',
          },
        ],
      },
    ],
  },
];

function JudgeScoreDetails(props: any) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState<any>([]);
  const [participant, setParticipant] = useState('');
  const [dataJudge, setDataJudge] = useState<any>(recordsJudge);
  const { eventId } = useParams();
  const handleModal = (id: any, name: any) => {
    setId(id);
    setShow(true);
    setParticipant(name);
  };

  const fetchData = () => {
    setLoading(true);
    EventService.getJudgementByEventWithScoreAndId(eventId).then((res) => {
      setDataJudge(res.data.message);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const getAcc = (rIdx: any, jIdx: any, cIdx: any, cell: any) => {
    return cell?.judge[jIdx]?.criteria[cIdx]?.score || '-';
  };

  function scoreData() {
    const arr = dataJudge.map((rec: any, rIdx: any) =>
      rec.judge.map((j: any, jIdx: any) => {
        const obj = {
          Header: 'Individual Scores',
          id: v4(),
        };
        const newArr: any = [];
        j.criteria.map((c: any, cIdx: any) => {
          newArr.push({
            Header: c.criteriaName,
            accessor: (cell: any) => getAcc(rIdx, jIdx, cIdx, cell),
            id: v4(),
          });
        });
        return {
          ...obj,
          columns: newArr,
          id: v4(),
        };
      }),
    );
    return arr[0] || [];
  }
  const headers = [
    { label: 'Email', key: 'mailid' },
    { label: 'Participant', key: 'username' },
    { label: 'Submitted Time', key: 'SubmittedAt' },
  ];

  const columns = [
    {
      Header: 'Participant',
      accessor: 'userName',
    },
    {
      Header: 'Email',
      accessor: 'mailid',
      Cell: (cell: any) => <div className="lowercase">{cell.value}</div>,
    },
    ...scoreData(),
    {
      Header: 'Total Score',
      accessor: 'totalScore',
      Cell: (cell: any) => {
        return cell.value === undefined ? '-' : cell.value;
      },
    },

    {
      Header: 'Assign Score',
      accessor: 'id',
      Cell: (cell: any) => (
        <div>
          <button
            type="button"
            value={cell.row.values.id}
            className="btn btn-primary text-white buttonshow"
            onClick={() => handleModal(cell.row.values.id, cell.row.values.userName)}
          >
            <i className="bi bi-card-checklist text-white icon-check me-2" />
            Score
          </button>
        </div>
      ),
    },
    {
      Header: 'View',
      accessor: 'Action',
      Cell: (cell: any) => (
        <div>
          <button type="button" className="btn btn-primary">
            <Link to={`/submission/${eventId}/${cell.row.values.id}`}>
              <span
                id="boot-icon"
                className="bi bi-eye-fill text-white"
                style={{
                  fontSize: '1rem',
                  color: 'rgb(35, 198, 231)',
                }}
              />
            </Link>
          </button>
        </div>
      ),
    },
  ];
  return (
    <>
      {show ? (
        <JudgeScoringModal
          show={show}
          id={id}
          name={participant}
          onHide={() => {
            setShow(false);
          }}
        />
      ) : null}
      {loading ? (
        <Spinner />
      ) : (
        <TableComponent
          data={dataJudge}
          columns={columns}
          headers={headers}
          filename="Submission file.csv"
        />
      )}
    </>
  );
}
export default JudgeScoreDetails;
