import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import TableComponent from '../../components/Table/Table';
import EventService from '../../services/EventService';
import Spinner from '../../shared/Loading-Spinner/Spinner';

const records = [
  {
    userName: '',
    judge: [
      {
        name: 'Judge',
        criteria: [
          {
            criteriaName: 'Criteria Name',
            score: '',
          },
        ],
      },
    ],
    Totalscore: '',
  },
];

function ScoreBoardDetails() {
  const [data, setData] = useState<any>(records);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const { eventId } = useParams();
  const fetchData = () => {
    setIsLoading(true);
    EventService.getJudgementByEventWithScore(eventId).then((res) => {
      setFileName(`${res.data.message[0].eventName}-ScoreBoard File.csv`);
      setData(res.data.message);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const getAcc = (rIdx: any, jIdx: any, cIdx: any, cell: any) => {
    return cell?.judge[jIdx]?.criteria[cIdx]?.score || '-';
  };
  function scoreData() {
    const arr = data.map((rec: any, rIdx: any) =>
      rec.judge.map((j: any, jIdx: any) => {
        const obj = {
          Header: `${j.name}'s Score`,
          id: v4(),
        };
        const newArr: any = [];
        j.criteria.map((c: any, cIdx: any) => {
          newArr.push({
            Header: c.criteriaName,
            accessor: (cell: any) => getAcc(rIdx, jIdx, cIdx, cell),
            id: v4(),
          });
        });
        return {
          ...obj,
          columns: newArr,
          id: v4(),
        };
      }),
    );
    return arr[0] || [];
  }
  const headers = [
    { label: 'Participant', key: 'userName' },
    { label: 'Total Score', key: 'totalScore' },
  ];

  const columns = [
    {
      Header: 'Participant',
      accessor: 'userName',
      id: v4(),
    },
    ...scoreData(),
    {
      Header: 'Total Score',
      accessor: 'totalScore',
    },
  ];
  return isLoading ? (
    <Spinner />
  ) : (
    <TableComponent data={data} columns={columns} headers={headers} filename={fileName} />
  );
}
export default ScoreBoardDetails;
