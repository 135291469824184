import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';

import SearchData from './LeaderBoardSearch';
import { useAppSelector } from '../../redux/store';

import '../../screens/LeaderBoardDetails/LeaderBoard.scss';

type Props = {
  columns: any;
  data: any;
  headers: any;
  filename: any;
};
function Table(props: Props) {
  const data = useMemo(() => props.data, [props.data]);
  const columns = useMemo(() => props.columns, [props.columns]);
  const headers = useMemo(() => props.headers, [props.headers]);
  const filename = useMemo(() => props.filename, [props.filename]);
  const { isOrganizer } = useAppSelector((state) => state.rootReducer.EventRoleReducer.roles);
  const { getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
  );

  const { globalFilter } = state;

  return (
    <>
      <div className="d-flex  justify-content-between filter export-button filter">
        <SearchData filter={globalFilter} setFilter={setGlobalFilter} />
        {isOrganizer && (
          <div className="export-csv">
            <button type="button" className="btn btn-outline-primary export-button">
              <CSVLink data={data} headers={headers} filename={filename}>
                Export
              </CSVLink>
            </button>
          </div>
        )}
      </div>
      <div>
        <table>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col">
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="bi bi-arrow-down" />
                        ) : (
                          <i className="bi bi-arrow-up" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default Table;
