import React, { useMemo } from 'react';
import { Placeholder } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFlexLayout, useGlobalFilter, useSortBy, useTable } from 'react-table';

import FilterData from './FilterData';

import './table.scss';

type Props = {
  columns: any;
  data: any;
  headers: any;
  filename: any;
};
function Table(props: any) {
  const data = useMemo(() => props.data, [props.data]);
  const columns = useMemo(() => props.columns, [props.columns]);
  const { isOrganizer } = useSelector((state: any) => state.rootReducer.EventRoleReducer.roles);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 130,
      width: 130,
      maxWidth: 500,
    }),
    [],
  );
  const headers = useMemo(() => props.headers, [props.headers]);
  const filename = useMemo(() => props.filename, [props.filename]);
  const { eventId } = useParams();

  const { getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    { columns, data, defaultColumn },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
  );

  const { globalFilter } = state;

  return (
    <div className={`table-div ${props.cname}`}>
      <div className="d-flex  justify-content-between filter export-button">
        <FilterData filter={globalFilter} setFilter={setGlobalFilter} />
        {isOrganizer && (
          <div className="export-csv">
            <button type="button" className="btn btn-outline-primary export-button">
              <CSVLink data={data} filename={filename} headers={headers}>
                Export
              </CSVLink>
            </button>
          </div>
        )}
      </div>
      <div className="containing-div">
        <table>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={`${column.render('Header')}`}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="bi bi-arrow-down" />
                        ) : (
                          <i className="bi bi-arrow-up" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.value ||
                        (cell.value === 0 && cell.column.Header === 'Total Score') ||
                        (cell.value === undefined && cell.column.Header !== 'Email') ? (
                          cell.render('Cell')
                        ) : (
                          <Placeholder animation="glow">
                            <Placeholder lg={12} />
                          </Placeholder>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Table;
