import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';

import 'bootstrap/dist/js/bootstrap.bundle';

import store from './redux/store';
import App from './App';
import awsConfig from './aws-exports';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import './styles/styles.scss';

Amplify.configure(awsConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
