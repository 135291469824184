import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const DndWrapper = React.memo((props: any) => {
  const [context, setContext] = useState<any>(null);

  useEffect(() => {
    setContext(document.getElementById(props.id) || null);
  }, [props.id]);

  return context ? (
    <DndProvider backend={HTML5Backend} options={{ rootElement: context }}>
      {props.children}
    </DndProvider>
  ) : null;
});
