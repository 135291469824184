import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ApprovalService from '../../services/ApprovalService';
import EventService from '../../services/EventService';
import logger from '../../utils/logger';

function DownloadFile() {
  const { eventId } = useParams();
  let { key } = useParams();
  const navigate = useNavigate();
  async function toDataURL(url: any) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }
  useEffect(() => {
    ApprovalService.getEventUserRole(eventId).then(async (res) => {
      if (res.data.message.roles.isOrganizer) {
        key = key?.replaceAll('/', '%2f');
        EventService.getDownloadSignedUrl(key).then(async (res) => {
          if (res.data.message === 'Unauthorized') {
            logger.error('Unauthorized to download the file!');
            toast.error('Unauthorized to download the file!');
          } else {
            const url = await toDataURL(res.data.message);
            const a: any = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            a.setAttribute('href', url);
            a.setAttribute('target', '_self');
            a.download = 'Download';
            a.click();
            a.remove();
          }
        });
      } else {
        logger.error('Unauthorized to download the file!');
        toast.error('Unauthorized to download the file!');
      }
      navigate('/');
    });
  }, []);
  return <div />;
}

export default DownloadFile;
