import { Link } from 'react-router-dom';

function ViewEntries(props: any) {
  const { id } = props;
  return (
    <Link
      to={`/event/${id}/entries`}
      className="btn btn-sm outline btn-outline-primary text-uppercase text-primary"
    >
      <i className="bi bi-list-ul" />
      <span>&nbsp; VIEW ENTRIES</span>
    </Link>
  );
}

export default ViewEntries;
