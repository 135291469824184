import { AnyAction } from 'redux';

import eventTypes from '../../actions/EventAction/eventTypes';

type EventState = {
  isLoading: boolean;
  events: {}[];
};

const initialState: EventState = {
  isLoading: false,
  events: [],
};
const EventReducer = (state: EventState = initialState, action: AnyAction) => {
  switch (action.type) {
    case eventTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case eventTypes.GET_EVENTS:
      return {
        ...state,
        events: [...action.payload],
      };

    case eventTypes.CREATE_EVENTS:
      return state;

    case eventTypes.UPDATE_EVENTS:
      return state;

    case eventTypes.DELETE_EVENTS:
      const filterEvent = state.events.filter((event: any) => event.id !== action.payload);
      return {
        ...state,
        events: [...filterEvent],
      };

    default:
      return state;
  }
};

export default EventReducer;
